import { Typography } from '@mui/material'
import { Button } from 'flowbite-react'
import React from 'react'
import { productsArray } from './ProductsArray'
import { CartContext } from '../Cart/CartContext'
import { useContext } from 'react'
import { FiCheckSquare, FiX } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";

const NewProducts = () => {
  const cart = useContext(CartContext);
  const [notifications, setNotifications] = React.useState([]);
  const NOTIFICATION_TTL = 5000;
  const removeNotif = (id) => {
    setNotifications((pv) => pv.filter((n) => n.id !== id));
  };
  const Notification = ({ text, id, removeNotif }) => {
    React.useEffect(() => {
      const timeoutRef = setTimeout(() => {
        removeNotif(id);
      }, NOTIFICATION_TTL);
  
      return () => clearTimeout(timeoutRef);
    }, [removeNotif, id]);
  
    return (
      <motion.div
        layout
        initial={{ y: 0, scale: 1 }}
        animate={{ y: 0, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 0.15, ease: "easeOut" }}
        className="p-2 flex items-start rounded gap-2 text-xs font-medium text-white bg-black pointer-events-auto"
      >
        <a role='button' href='/cart' className='flex gap-2 text-white'>
        <FiCheckSquare className=" mt-0.5" />
        <span>{text}</span>
        </a>
        <button onClick={() => removeNotif(id)} className="ml-auto mt-0.5">
          <FiX />
        </button>
      </motion.div>
    );
  };
  
  const generateRandomNotif = () => {

    const data = {
      id: 1,
      text: "Item added to cart!",
    };
  
    return data;
  };
  

  return (


<section class="py-12 sm:py-16 lg:py-20 bg-gray-50">
    <div class="px-8 mx-auto sm:px-12 lg:px-16 max-w-7xl">
        <div class="flex items-center justify-center lg:justify-between">
            <h2 class="text-2xl font-bold text-gray-900 sm:text-3xl">Our Recent Sellers</h2>

            <div class="hidden lg:flex">
                <a href="/shop" title="" class="inline-flex items-center justify-center p-1 -m-1 text-sm font-bold text-gray-600 transition-all duration-200 rounded-md focus:text-gray-900 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2 focus:outline-none hover:text-gray-900">
                    Check all items
                    <svg class="w-5 h-5 ml-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </a>
            </div>
        </div>

        <div class="grid grid-cols-1 gap-4  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:mt-10">
        {productsArray[0].products.slice(17,25).map((obj, idx) => {
                const pricesArray = obj.id.split(',')
                let test = pricesArray[0]
                console.log(pricesArray[0])
                const handleClick = () => {
                  cart.addOneToCart(test)
                  setNotifications((pv) => [generateRandomNotif(), ...pv])
                  setTimeout(500);
              };
                return (
     
            <div key={idx} class="relative flex flex-col flex-wrap- space-between  bg-white border border-gray-200 rounded-xl ">
                <div class="relative aspect-w-1 aspect-h-1">
                    <div class="aspect-w-1 aspect-h-1">
                        <img class="object-cover w-full h-full p-4" src={obj.image} alt="" />
                    </div>

                    <div class="px-6 ">
                        <p class="text-xs font-medium tracking-widest text-gray-500 uppercase">SEAMOS-SISTA</p>
                        <h3 class="mt-2 text-sm font-medium text-gray-900">
                            <a href={`/products/${test}`} title="">
                                {obj.name}
                                <span class="absolute inset-0" aria-hidden="true"></span>
                            </a>
                        </h3>
                        <div class="flex items-center justify-between mt-2.5">
                            <div class="flex items-center space-x-px">
                                <svg class="w-3 h-3 text-yellow-400 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    />
                                </svg>
                                <svg class="w-3 h-3 text-yellow-400 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    />
                                </svg>
                                <svg class="w-3 h-3 text-yellow-400 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    />
                                </svg>
                                <svg class="w-3 h-3 text-yellow-400 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    />
                                </svg>
                                <svg class="w-3 h-3 text-yellow-400 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    />
                                </svg>
                            </div>
                        </div>
                        <p class="mt-2.5 text-sm font-bold text-gray-900">${obj.price[0].toFixed(2)}</p>
                    </div>
                </div>
                <div class="rounded-xl  ">
                    <div>
                    <button onClick={handleClick} type="button" class="flex rounded-xl items-center justify-center w-full mx-auto px-4 py-2.5 text-sm font-bold text-white transition-all duration-200 bg-gray-900">
                        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>
                        Add to cart
                    </button>
                    <div className="flex flex-col gap-1 w-72 fixed top-20 right-2 z-50 pointer-events-none">
        <AnimatePresence>
          {notifications.map((n) => (
            <Notification removeNotif={removeNotif} {...n} key={n.id} />
          ))}
        </AnimatePresence>
      </div>
      </div>
                </div>
            </div>
           
            )})}
        </div>

        <div class="mt-12 text-center lg:hidden">
            <a href="#" title="" class="inline-flex items-center justify-center p-1 text-sm font-bold text-gray-600 transition-all duration-200 rounded-md focus:text-gray-900 focus:ring-gray-900 focus:ring-2 focus:ring-offset-2 focus:outline-none hover:text-gray-900">
                Check all items
                <svg class="w-5 h-5 ml-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
            </a>
        </div>
    </div>
</section>

  )
}

export default NewProducts