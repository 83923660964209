import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, Box, FormGroup, FormHelperText } from "@mui/material";
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { productsArray } from "./Products/ProductsArray";
import { useContext } from "react";
import { CartContext } from "./Cart/CartContext";
import { FiCheckSquare, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
const BundleForm = ({id}) => {
console.log(id)

const SpringModal = ({ isOpen, setIsOpen }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="bg-gradient-to-br from-blue-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
          >
            <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
            <div className="relative z-10">
              <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
                <FiAlertCircle />
              </div>
              <h3 className="text-3xl font-bold text-center mb-2">
              Are you ready to submit?
            </h3>
            <p className="text-center mb-6">
              If you're ready to submit your choices, please click next.
            </p>
              <div className="flex gap-2">
                <button
                  onClick={() => setIsOpen(false)}
                  className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                >
                  Nah, go back
                </button>
                <a href="/cart" onClick={handleClick}  className="bg-white mx-auto text-center hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded">
                <button  className=""
              >
                Next!
              </button>
              </a>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};



const cart = useContext(CartContext);
const navigate = useNavigate();
    const juiceProducts = productsArray[0].products.filter((product) =>
    product.name.toLowerCase().endsWith('juice')
  );
  const newJuiceProducts = juiceProducts.map(name => name.name);

  const [stepsComplete, setStepsComplete] = useState(0);
  const [tValues1, setTValues1] = useState();
  const numSteps = 2;

  const handleSetStep = (num) => {
    if (
      (stepsComplete === 0 && num === -1) ||
      (stepsComplete === numSteps && num === 1)
    ) {
      return;
    }

    setStepsComplete((pv) => pv + num);
  };

  const [selectedChoices1, setSelectedChoices1] = React.useState([]);




  const [state1, setState1] = React.useState({
    gold: false,
    strawberry1: false,
    cherry1: false,
    bladderwrack: false,
    burdock: false,
    elderberry: false,
    ginger: false,
    sarsaparilla: false,
  });




  const handleChange1 = (event) => {
    const { name, checked } = event.target;
    const selectedOptions = Object.values(state1).filter((v) => v);
  
    if (checked && selectedOptions.length >= 2) {
      return; // Prevent selecting more than two options
    }
  
    setState1((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: checked,
      };
      const selected = Object.keys(updatedState).filter((key) => updatedState[key]);
      setSelectedChoices1(
        selected.map((choice) => {
          if (choice.includes(' ')) {
            return choice
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
          } else {
            let formattedChoice = choice.replace(/([a-z])([A-Z])/g, '$1-$2');
            formattedChoice = formattedChoice.replace(/\d/g, '');
            return formattedChoice.charAt(0).toUpperCase() + formattedChoice.slice(1);
          }
        })
      );
  

      return updatedState;
    });
  };
  
  
  React.useEffect(() => {
      setTValues1(selectedChoices1);
      console.log(selectedChoices1);
    
  }, [selectedChoices1]);
  

  const {sarsaparilla, strawberry1, ginger, burdock, bladderwrack, elderberry, gold, cherry1} = state1;
  const error1 = Object.values(state1).filter((v) => v).length !== 2;
  const [isOpen, setIsOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const NOTIFICATION_TTL = 5000;
  const removeNotif = (id) => {
    setNotifications((pv) => pv.filter((n) => n.id !== id));
  };
  const Notification = ({ text, id, removeNotif }) => {
    React.useEffect(() => {
      const timeoutRef = setTimeout(() => {
        removeNotif(id);
      }, NOTIFICATION_TTL);
  
      return () => clearTimeout(timeoutRef);
    }, [removeNotif, id]);
  
    return (
      <motion.div
        layout
        initial={{ y: 0, scale: 1 }}
        animate={{ y: 0, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 0.35, ease: "easeOut" }}
        className="p-2 flex items-start rounded gap-2 text-xs font-medium text-white bg-black pointer-events-auto"
      >
         <a role='button' href='/cart' className='flex gap-2 text-white'>
        <FiCheckSquare className=" mt-0.5" />
        <span>{text}</span>
        </a>
        <button onClick={() => removeNotif(id)} className="ml-auto mt-0.5">
          <FiX />
        </button>
      </motion.div>
    );
  };
  
  const generateRandomNotif = () => {

    const data = {
      id: 1,
      text: "Item added to cart!",
    };
  
    return data;
  };
  const isPrevButtonHidden = stepsComplete === 0 || stepsComplete === 2;
  const isNextButtonHidden = stepsComplete === 2;
  const handleClick = () => {
          // Store the selected choices in local storage
          const selectedGels = JSON.parse(localStorage.getItem("selectedChoices1")) || [];
          selectedGels.push([...selectedChoices1]);
          localStorage.setItem("selectedChoices1", JSON.stringify(selectedGels));
      
    cart.addOneToCart(id)
    setNotifications((pv) => [generateRandomNotif(), ...pv])
};
const formRef = useRef(null);
React.useEffect(() => {
  if (formRef.current && stepsComplete === 1) {
    formRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest", offsetTop: "-25" });
  }
}, [stepsComplete]);
  return (
    <div ref={formRef}>
      <div  >
        <Steps numSteps={numSteps} stepsComplete={stepsComplete} />
        <div className="p-2 my-6 h-fit w-full bg-gray-100 border-2 border-dashed border-gray-200 rounded-lg">
        {stepsComplete === 0 && (
               <Box sx={{ display: 'flex' }}>



               {/* Second Form */}
               <FormControl
                 required
                 error={error1}
                 component="fieldset"
                 sx={{ m: 3 }}
                 variant="standard"
               >
                 <FormLabel component="legend">Pick two gels:</FormLabel>
                 <FormGroup>
                 <FormControlLabel
                     control={
                       <Checkbox checked={gold} onChange={(e) => handleChange1(e)} name="gold" />
                     }
                     label="Gold"
                   />
                   
                   <FormControlLabel
                     control={
                       <Checkbox checked={strawberry1} onChange={(e) => handleChange1(e)} name="strawberry1" />
                     }
                     label="Strawberry"
                   />
                   <FormControlLabel
                     control={
                       <Checkbox checked={cherry1} onChange={(e) => handleChange1(e)} name="cherry1" />
                     }
                     label="Cherry"
                   />
                   <FormControlLabel
                     control={
                       <Checkbox checked={bladderwrack} onChange={(e) => handleChange1(e)} name="bladderwrack" />
                     }
                     label="Bladderwrack"
                   />
                   <FormControlLabel
                     control={
                       <Checkbox checked={burdock} onChange={(e) => handleChange1(e)} name="burdock" />
                     }
                     label="Burdock"
                   />
                   <FormControlLabel
                     control={
                       <Checkbox checked={elderberry} onChange={(e) => handleChange1(e)} name="elderberry" />
                     }
                     label="Elderberry"
                   />
                   <FormControlLabel
                     control={
                       <Checkbox checked={ginger} onChange={(e) => handleChange1(e)} name="ginger" />
                     }
                     label="Ginger"
                   />
                    <FormControlLabel
                     control={
                       <Checkbox checked={sarsaparilla} onChange={(e) => handleChange1(e)} name="sarsaparilla" />
                     }
                     label="Sarsaparilla"
                   />
                 </FormGroup>
               </FormControl>
             </Box>
)}


{stepsComplete === 1 && (
  // Render components for step 2
  <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">Confirm your selections:</FormLabel>
  <div>
    {tValues1.map((value, index) => (
      <div className="py-2" key={index}>
        {index + 1}. {value} Sea Moss Gel
        {index !== tValues1.length - 1 && <br />}
      </div>
    ))}
  </div>

</FormControl>
)}

{stepsComplete === 2 && (
  // Render components for step 3
   <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">Thank you! Your order has been received!</FormLabel>
</FormControl>
)}



        </div>
        <div className="flex items-center justify-end gap-2">
        {!isPrevButtonHidden && (
        <button className="px-4 py-1 rounded hover:bg-gray-100 text-black" onClick={() => handleSetStep(-1)}>
          Prev
        </button>
      )}
     {stepsComplete === 1 ? (
      <div>
  <button
   className="text-white bg-[#312ECB] hover:bg-blue-800 focus:ring-4  focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-4 md:mr-2    focus:outline-none  "
    onClick={() => setIsOpen(true)}
    type="submit"
  >
    Submit Order
  </button>
  <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />
   {/* <div className="flex flex-col gap-1 w-72 fixed top-12 right-2 z-50 pointer-events-none">
   <AnimatePresence>
     {notifications.map((n) => (
       <Notification removeNotif={removeNotif} {...n} key={n.id} />
     ))}
   </AnimatePresence>
 </div> */}
 </div>
) : (
  <button
    className="px-4 py-1 rounded bg-black text-white"
    onClick={() => handleSetStep(1)}
  >
    Next
  </button>
)}
        </div>
      </div>
      </div>
  );
};

const Steps = ({ numSteps, stepsComplete }) => {
  const stepArray = Array.from(Array(numSteps).keys());

  return (
    <div className="flex items-center justify-between gap-3">
      {stepArray.map((num) => {
        const stepNum = num + 1;
        const isActive = stepNum <= stepsComplete;
        return (
          <React.Fragment key={stepNum}>
            <Step num={stepNum} isActive={isActive} />
            {stepNum !== stepArray.length && (
              <div className="w-full h-1 rounded-full bg-gray-200 relative">
                <motion.div
                  className="absolute top-0 bottom-0 left-0 bg-indigo-600 rounded-full"
                  animate={{ width: isActive ? "100%" : 0 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                />

              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Step = ({ num, isActive }) => {
  return (
    <div className="relative">
      <div
        className={`w-10 h-10 flex items-center justify-center shrink-0 border-2 rounded-full font-semibold text-sm relative z-10 transition-colors duration-300 ${
          isActive
            ? "border-indigo-600 bg-indigo-600 text-white"
            : "border-gray-300 text-gray-300"
        }`}
      >
        <AnimatePresence mode="wait">
          {isActive ? (
            <motion.svg
              key="icon-marker-check"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1.6em"
              width="1.6em"
              xmlns="http://www.w3.org/2000/svg"
              initial={{ rotate: 180, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              exit={{ rotate: -180, opacity: 0 }}
              transition={{ duration: 0.125 }}
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
            </motion.svg>
          ) : (
            <motion.span
              key="icon-marker-num"
              initial={{ rotate: 180, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              exit={{ rotate: -180, opacity: 0 }}
              transition={{ duration: 0.125 }}
            >
              {num}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {isActive && (
        <div className="absolute z-0 -inset-1.5 bg-indigo-100 rounded-full animate-pulse" />
      )}
    </div>
  );
};

export default BundleForm;