import './App.css';
import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import CartProvider from './components/Cart/CartContext';
import SingleProductPage from './pages/SingleProductPage';
import ProductPage from './pages/ProductPage';
import CartProduct from './components/Cart/CartProduct';
import AboutUs from './components/AboutUs/AboutUs';
import Questions from './pages/Questions';
import FAQS from './pages/FAQS';
import Disclaimer from './pages/Disclaimer';
import Success from './pages/Success';
import { useContext } from 'react';
import { CartContext } from './components/Cart/CartContext';
import Success1 from './pages/Success1';
import NewNavbar from './components/Navbar/NewNavbar';
import NewFooter from './components/Footer/NewFooter';
import Shop from './pages/Shop';
import NewSingleProduct from './pages/NewSingleProduct';
import NewCart from './components/Cart/NewCart';
import NewAboutUs from './components/AboutUs/NewAboutUs';
import LearnMore from './pages/LearnMore';
import NewContact from './components/Contact/NewContact';
import Contact1 from './pages/Contact';
import Topbar from './components/Topbar';

function App() {
  const cart = useContext(CartContext);


  return (
    <React.Fragment>
      <CartProvider>
        <Topbar />
      <NewNavbar />
      <Routes>
        <Route exact children path='/' element={<Home />} />
        <Route path='/contact' element={<Contact1/>} />
        <Route path='/cart' element={<NewCart />} />
        <Route path='/about-us' element={<NewAboutUs />} />
        <Route path='/learn-more' element={<LearnMore />} />
        <Route exact children path='/products/:id' element={<NewSingleProduct/>} />
        <Route exact children path='/shop' element={<Shop />} />
        <Route exact children path='/questions' element={<Questions />} />
        <Route exact children path='/faqs' element={<FAQS />} />
        <Route exact children path='/success' element={<Success />} />
        <Route exact children path='/localSuccess' element={<Success1 />} />
        <Route exact children path='/legal-disclaimer' element={<Disclaimer />} />
        <Route exact children path='/.well-known/apple-developer-merchantid-domain-association' element={<Disclaimer />} />
      </Routes>
      {/* <Footer /> */}
      <NewFooter />
      </CartProvider>
      
    </React.Fragment>
  );
}

export default App;
