import gold from '../../images/gold gel product pic.png'
import purple from '../../images/moss gel purple product pic.png'
import cherry from '../../images/cherrygel1.png'
import cherry2 from '../../images/cherrynewgel2.png';
import cherry3 from '../../images/cherrygell3.png'
import strawberry from '../../images/strwbrygel.png'
import strawberry2 from '../../images/strawberrygel.png'
import strawberry3 from '../../images/strawberry2.png'
import test from '../../images/Untitled design 2.png'
import bladderwrack from '../../images/bladderwrack gel product img.png'
import burdock from '../../images/burdock gel product img.png'
import elder from '../../images/elderberry gel product img.png'
import ginger from '../../images/sea moss ginger gel product img.png'
import sarsa from '../../images/sarsaparilla root gel product img full.png'
import strawberryjuice from '../../images/strawberry juice.png'
import sp from '../../images/strawberrypineapplejuice.png'
import dl from '../../images/dragonfruit lemon juice.png'
import cl from '../../images/cherrylimejuice.png'
import lg from '../../images/limeginger juice.png'
import lemon from '../../images/llemon juice.png'
import cherryjuice from '../../images/cherryjuice.png'
import sl from '../../images/new strbry lemon juice.png'
import bundle from '../../images/seamoss bundle 2 gels.jpeg'
import newgold from '../../images/goldgel.jpeg'
import newgold2 from '../../images/goldgel2.jpeg'
import newgold3 from '../../images/goldgel3.jpeg'
import herbs from '../../images/customizedherbgel.png'
import cherrywalk from '../../images/cherry walk bundle.png'
import citrus from '../../images/citrus journey bundle.png'
import juicecleanse from '../../images/juice cleanse2.png'
import citrus2 from '../../images/new new citrus journey.png'
import strawbundle from '../../images/strawberrybundle.png'
import wellness from '../../images/wellness bundle.png'
import herbtea from '../../images/Herbal Soursop Tea.png'
import mucus from '../../images/mucusbuster.png'
import eldersyrup from '../../images/elderberry.png'
const productsArray = [
  {
    set: "Sea Moss Gel",
    products: [
      { name: "Gold Sea Moss Gel", image: newgold, image2: newgold2, image3: newgold3, size: ["8 oz.", "16 oz.", "32 oz."], price: [14.89, 28.89, 44.89], id: "price_1O6JLxKNMdnS9NXCLNjfL3Gk,price_1O6JLxKNMdnS9NXCUOtIiAtN,price_1O6JLxKNMdnS9NXC3S9HVeZI" },
      // { name: "Purple Sea Moss Gel", image: purple, size: [ "8 oz.", "16 oz.", "32 oz."],  price: [14.89, 28.89, 44.89], id: 'price_1ODh5xKNMdnS9NXCQw0euCdS,price_1ODh6XKNMdnS9NXCY17BfnLT,price_1ODh6lKNMdnS9NXCyGOF5rIX'},
      { name: "Strawberry Sea Moss Gel", image: strawberry, image2: strawberry2, image3: strawberry3, size: ["8 oz.", "16 oz.", "32 oz."], price: [16.89, 32.89, 50.50], id: "price_1ODh9DKNMdnS9NXCXvRbd0QN,price_1ODh9QKNMdnS9NXC33oRRhjD,price_1ODh9aKNMdnS9NXCID9j6v7U" },
      { name: "Cherry Sea Moss Gel", image: cherry, image2: cherry2, image3: cherry3, size: [ "8 oz.", "16 oz.", "32 oz."],  price: [16.89, 32.89, 50.50], id:'price_1ODhCNKNMdnS9NXCQIr98EHh,price_1ODhCbKNMdnS9NXCHqqrtzux,price_1ODhCkKNMdnS9NXCbaRufWsw' },
      // { name: "Customized Herb Gel", image: herbs,  size: ["8 oz.", "16 oz.", "32 oz."], price: [17.89, 33.89, 53.50], id: "price_1OEi1rKNMdnS9NXCPCpOSwHV,price_1PPDS9KNMdnS9NXCmjD7BHoh,price_1PPDSLKNMdnS9NXCn5khCmlT" },
      { name: "Bladderwrack Sea Moss Gel", image: herbs, size: [ "8 oz.", "16 oz.", "32 oz."], price: [17.89, 33.89, 53.50], id: "price_1ODhc8KNMdnS9NXCs9FBhsJE,price_1ODhcUKNMdnS9NXCcOZUC7JD,price_1ODhcdKNMdnS9NXCkFK5Wvej"},
      { name: "Burdock Sea Moss Gel", image: herbs, size: [ "8 oz.", "16 oz.", "32 oz."], price: [17.89, 33.89, 53.50], id: "price_1ODhfDKNMdnS9NXCgTPb6SXA,price_1ODhfnKNMdnS9NXCu7hqbqGY,price_1ODhfvKNMdnS9NXCHnmayjJw"},
      { name: "Elderberry Sea Moss Gel", image: herbs, size: [ "8 oz.", "16 oz.", "32 oz."], price: [17.89, 33.89, 53.50], id: "price_1ODhq6KNMdnS9NXCTKho0F65,price_1ODhqJKNMdnS9NXC9o2d13Ry,price_1ODhqRKNMdnS9NXC3f3rhIl9"},
      { name: "Ginger Sea Moss Gel", image: herbs, size: [ "8 oz.", "16 oz.", "32 oz."], price: [17.89, 33.89, 53.50], id: "price_1ODhruKNMdnS9NXCgONs3o1s,price_1ODhs5KNMdnS9NXCexENSRhi,price_1ODhsCKNMdnS9NXCMfYmvku2"},
      { name: "Sarsaparilla Sea Moss Gel", image: herbs, size: [ "8 oz.", "16 oz.", "32 oz."], price: [17.89, 33.89, 53.50], id: "price_1ODht6KNMdnS9NXCwtymZbHQ,price_1ODhtIKNMdnS9NXCudicpj3e,price_1ODhtPKNMdnS9NXCcdYvgi8v"},
      { name: "Ginger-Shot (Mucus Buster)", image: mucus, size: ["2 oz."], price: [2], id: 'price_1PPEI3KNMdnS9NXCsX3cvxXP'},
      { name: "Elderberry Syrup", image: eldersyrup, size: ["8 oz."], price: [10], id: 'price_1PPEJDKNMdnS9NXCs3U6v5Y0'},
      { name: "Herbal Soursop Tea", image: herbtea, size: ["16 oz."], price: [10], id: 'price_1PPEL8KNMdnS9NXCOGtLAVrX'},
      { name: "Strawberry Sea Moss-Infused Fruit Juice", image: strawberryjuice, size: ["Single - 16 oz.", "6-Pack", "8-Pack"],  price: [11.99, 60, 80], id: "price_1ODhEgKNMdnS9NXCZZpIUyT9,price_1ODhEsKNMdnS9NXCnLqFuTxn,price_1ODhF1KNMdnS9NXC3dL5Pu5w"},
      { name: "Strawberry Pineapple Sea Moss-Infused Fruit Juice", image: sp, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id:"price_1ODhGaKNMdnS9NXCvKWSn8QL,price_1ODhGlKNMdnS9NXCkgstP1qZ,price_1ODhGsKNMdnS9NXC8wAL565Q"},
      { name: "Dragonfruit Lemon Sea Moss-Infused Fruit Juice", image: dl, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id: "price_1ODhIlKNMdnS9NXCD2CAws28,price_1ODhIvKNMdnS9NXC2CW44tOI,price_1ODhJ1KNMdnS9NXCOAXYmJeI"},
      { name: "Cherry Limeade Sea Moss-Infused Fruit Juice", image: cl, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id: "price_1ODhKhKNMdnS9NXCZ5DYyQzz,price_1ODhKuKNMdnS9NXCEWNOyBIz,price_1ODhL1KNMdnS9NXCdtmTH71s"},
      { name: "Lime Ginger Sea Moss-Infused Fruit Juice", image: lg, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id: "price_1ODhMcKNMdnS9NXCQZwDMKYB,price_1ODhMnKNMdnS9NXCjW6IxMny,price_1ODhMuKNMdnS9NXC2zg6KgzY"},
      { name: "Lemon Sea Moss-Infused Fruit Juice", image: lemon, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id: "price_1ODhOOKNMdnS9NXCotJ5E4iZ,price_1ODhOZKNMdnS9NXCBw9FZJpe,price_1ODhOhKNMdnS9NXC4XhSNfI7"},
      { name: "Cherry Sea Moss-Infused Fruit Juice", image: cherryjuice, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id: "price_1ODhQYKNMdnS9NXC8QG90ysJ,price_1ODhQiKNMdnS9NXCztsoxo7Y,price_1ODhQpKNMdnS9NXCM3tNLBXN"},
      { name: "Strawberry Lemon Sea Moss-Infused Fruit Juice", image: sl, size: ["Single - 16 oz.", "6-Pack", "8-Pack"], price: [11.99, 60, 80], id: "price_1ODhSLKNMdnS9NXCCzxY39K3,price_1ODhSoKNMdnS9NXCKSct6trz,price_1ODhSvKNMdnS9NXCH3mOghhN"},
      { name: "SeaMoss Bundle Collection", image: bundle, size: ["8 Juices: 4 Flavor Limit, 2 Gels"], price: [105.89], id: 'price_1ODiOrKNMdnS9NXCPUekfbgA'},
      { name: "SeaMoss Juice Cleanse", image: juicecleanse, size: ["3-Day Cleanse: 15 Juices", "5-Day Cleanse: 25 Juices", "7-Day Cleanse: 35 Juices"], price: [110, 180, 250], id: 'price_1PPEF8KNMdnS9NXC1zYuMfS7,price_1PPEFbKNMdnS9NXCgpSo8NTJ,price_1PPEFmKNMdnS9NXCw10up4JW'},
      { name: "SeaMoss Wellness Bundle Collection", image: wellness, size: ["Wellness Bundle"], price: [59], id: 'price_1PPEKDKNMdnS9NXCRU1zgd7H'},
      { name: "Strawberry Blast Bundle Collection", image: strawbundle, size: ["16 oz."], price: [45], id: 'price_1PPEM7KNMdnS9NXC9sDTEj5p'},
      { name: "Citrus Journey Bundle Collection", image: citrus, size: ["16 oz."], price: [53], id: 'price_1PPENvKNMdnS9NXCQ7yZ59dj'},
      { name: "Cherry Walk Bundle Collection", image: cherrywalk, size: ["16 oz."], price: [35], id: 'price_1PPEOmKNMdnS9NXCkRXTxpNa'},
    ],
  },
];



function getProductData(id) {
  let productData = productsArray.map((pop) => pop.products.find(product => product.id.split(',').includes(id)));

  console.log(productData);
  if (productData === undefined) {
    console.log('Product data not found for ID:' + id);
  }
  return productData;
}

function getProductData2(id) {
  let productData = productsArray.map((pop) => pop.products.find(product => product.id === id));

  console.log(productData);
  if (productData === undefined) {
    console.log('Product data not found for ID:' + id);
  }
  return productData;
}

function getProductData1(id) {
  let foundId = null;
  let foundId1 = null;
  let foundName = null;
  let foundPic = null;
  let foundSize = null;
  let foundIndex = 0;
  let foundGelOptions = false;
  console.log(id);
  productsArray.forEach((pop) => {
    pop.products.forEach((product, index) => {
      const idArray = product.id.split(",");
      foundIndex = idArray.indexOf(id);
      if (foundIndex !== -1) {
        foundName = product.name;
        foundId = product.price[foundIndex];
        foundPic = product.image;
        foundSize = product.size[foundIndex];
      }
      console.log(foundIndex, "foundIndex");
      return (foundId1, foundIndex);
    });
  });
if (foundName === 'SeaMos Bundle Collection') {
  foundGelOptions = true;
}
  console.log(foundId);
  if (foundName === undefined) {
    console.log('Product data not found for ID: ' + id);
  }

  return [foundName, foundIndex, foundId, foundPic, foundSize, foundGelOptions];

  
}









export { getProductData, productsArray, getProductData1, getProductData2 };
