import React from 'react'
import { Typography, AccordionDetails, Accordion, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const FAQS = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <div className='p_container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '50px'}}>
<Typography className='text-center' variant={isMobile ? 'h2' : 'h1'} sx={{fontWeight: 'normal', fontFamily: 'Bebas Neue', color: '#557174', marginTop: '30px'}}>
          Faqs
          </Typography>
          <Typography className='text-center' variant='h5' sx={{fontWeight: 'normal', fontFamily: 'Bebas Neue', color: '#557174', marginBottom: '30px'}}>
          below are some frequently asked questions concerning sea moss and our products.
          </Typography>
          <div className='container'>
          <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>Where is our sea moss sourced?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Our Sea Moss grows along the rocky coast of the Atlantic ocean, sourced from the pristine waters of the Caribbean Sea surrounding Jamaica 🇯🇲 & St. Lucia 🇱🇨 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>What are the health benefits of sea moss?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sea moss is rich in vitamins, minerals, and antioxidants that are beneficial for human health. It can support immune system function, promote digestion, and improve skin and hair health.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>What is the difference between irish moss and sea moss?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Our Sea Moss grows in warm, tropical oceans and has thick, stick-like branches. Irish moss also known as Chondrus Crispus grows in colder, northern oceans and has flat curly leaves. Both are part of the red algae family which makes them very similar in nutritional value.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>How do i use Sea Moss?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='p'>
          In its gel form Sea Moss can be consumed on its own or added to your favorite smoothies, protein shakes, teas and juices. Due to its versatile texture and minimal taste, Sea Moss Gel can even be used as a salad dressing, dip for fruit, face mask, hair mask or as a vegan thickening agent when cooking soups and stews.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>WHAT IS THE RECOMMENDED DOSAGE? IS IT THE SAME FOR ADULTS AND CHILDREN?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sea Moss is an all natural herb that can be consumed by both, adults and children. As with all things, it should be consumed in moderation. We recommend consuming 1-2 tablespoons of Sea Moss Gel per day. 

Please note: Before using any supplements, it is always advisable to consult with your own health care provider and/or nutritionist. Individual results may vary, dependent on your health condition, diet and any other supplements or medications that you may be taking. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>HOW LONG DOES SEA MOSS LAST?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sea Moss Gel should be made or purchased in small batches, as it is best when used within three weeks. It must be kept sealed and refrigerated. Please also use care to avoid cross contamination.

Please note: Sea Moss Gel has been known to vary in spoilage and is not intended to be stored but rather to be consumed. Signs of spoilage include an odd pungent aroma or taste, slimy coating on top layer and/or mold. If you find mold or suspect spoilage please discontinue use and reach out immediately.  
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'Bebas Neue',color: '#557174', fontSize: '1.5rem'}}>WHAT DOES SEA MOSS GEL TASTE LIKE?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            It has a jello-like texture.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>
    </div>
  )
}

export default FAQS