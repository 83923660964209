import React from "react";
import { useParams } from "react-router-dom";
import {
  getProductData,
  getProductData1,
  productsArray,
} from "../components/Products/ProductsArray";
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  FormHelperText,
  FormGroup,
  Checkbox,
  Box,
  Typography,
  Modal,
} from "@mui/material";
import { useContext } from "react";
import { CartContext } from "../components/Cart/CartContext";
import { BeatLoader } from "react-spinners";
import NewCart from "../components/Cart/NewCart";
import { motion, AnimatePresence } from "framer-motion";
import BundleForm from "../components/BundleForm";
import BundleForm2 from "../components/BundleForm 2";
import { FiArrowUpRight } from "react-icons/fi";
import { FiCheckSquare, FiX } from "react-icons/fi";
const NewSingleProduct = () => {
  const [data, setData] = React.useState(null);
  const cart = useContext(CartContext);
  const { id } = useParams();
  const [newId, setNewId] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const dogger = getProductData(id);
  console.log(newId);
  console.log(getProductData(id));
  const bundleProducts = productsArray[0].products.filter((product) =>
    product.name.toLowerCase().endsWith("collection")
  );
  const [juiceFlavors, setJuiceFlavors] = React.useState({
    strawberry: false,
    strawberryPineapple: false,
    dragonfruitLemon: false,
    cherryLimeade: false,
    limeGinger: false,
    lemon: false,
    cherry: false,
    strawberryLemon: false,
  });
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setJuiceFlavors((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  React.useEffect(() => {
    const trueValues = Object.keys(juiceFlavors).filter(
      (key) => juiceFlavors[key]
    );
    localStorage.setItem("trueValues", JSON.stringify(trueValues));
    console.log(trueValues);
  }, [juiceFlavors]);

  const {
    strawberry,
    strawberryPineapple,
    dragonfruitLemon,
    cherryLimeade,
    limeGinger,
    lemon,
    cherry,
    strawberryLemon,
  } = juiceFlavors;
  console.log(juiceFlavors);
  const selectedCheckboxes = [
    strawberryLemon,
    strawberryPineapple,
    strawberry,
    cherry,
    dragonfruitLemon,
    cherryLimeade,
    limeGinger,
    lemon,
  ];
  const selectedCount = selectedCheckboxes.filter(
    (checkbox) => checkbox
  ).length;
  const error = selectedCount !== 4;
  const listOfProducts = productsArray;
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  React.useEffect(() => {
    console.log(dogger[0]);
    setData(dogger[0]);
    console.log(dogger[0]?.size?.[0]);
    setSelectedValue(dogger[0]?.size?.[0]);
    dogger.forEach((item, i) => {
      if (item.size && item.size[0] === selectedValue) {
        setSelectedIndex(i);
      }
    });
  }, [id]);
  console.log([
    [data, "this is the data variable"],
    [selectedValue, "this is the selected value"],
  ]);
  console.log(dogger);
  console.log(selectedIndex);

  const getPrice = React.useMemo(() => {
    const prices = [
      dogger[0]?.price[0],
      dogger[0]?.price[1],
      dogger[0]?.price[2],
    ];
    console.log(prices);
    const selectedIndex = dogger[0]?.size.indexOf(selectedValue);
    console.log(selectedIndex);
    return prices[selectedIndex];
  }, [selectedValue, dogger]);

  const changeId = React.useMemo(() => {
    const newIdArray = data?.id.split(",");
    const selectedIndex = data?.size.indexOf(selectedValue);

    if (newIdArray && selectedIndex !== -1) {
      const newSelectedId = newIdArray[selectedIndex];
      setNewId(newSelectedId);
      console.log(newSelectedId);
      return newSelectedId;
    }
    // return newIdArray[selectedIndex];
  }, [data, selectedValue]);
  const [notifications, setNotifications] = React.useState([]);
  const NOTIFICATION_TTL = 5000;

  const Notification = ({ text, id, removeNotif }) => {
    React.useEffect(() => {
      const timeoutRef = setTimeout(() => {
        removeNotif(id);
      }, NOTIFICATION_TTL);
  
      return () => clearTimeout(timeoutRef);
    }, [removeNotif]);
  
    return (
      <motion.div
        layout
        initial={{ y: 0, scale: 1 }}
        animate={{ y: 0, scale: 1 }}
        exit={{ x: "100%", opacity: 0 }}
        transition={{ duration: 0.15, ease: "easeOut" }}
        className="p-2 flex items-start rounded gap-2 text-xs font-medium  text-white bg-black pointer-events-auto"
      >
          <a role='button' href='/cart' className='flex gap-2 text-white'>
        <FiCheckSquare className=" mt-0.5" />
        <span>{text}</span>
        </a>
        <button onClick={() => removeNotif(id)} className="ml-auto mt-0.5">
          <FiX />
        </button>
      </motion.div>
    );
  };
  
  const generateRandomNotif = () => {

    const data = {
      id: 1,
      text: "Item added to cart!",
    };
  
    return data;
  };
  const removeNotif = (id) => {
    setNotifications((pv) => pv.filter((n) => n.id !== id));
  };
  const [isAdded, setIsAdded] = React.useState(false);
  const [text, setText] = React.useState("Add to cart");
  const handleClick = () => {
    cart.addOneToCart(newId);
    setIsAdded(true);
    setTimeout(afterClick, 500);
    setNotifications((pv) => [generateRandomNotif(), ...pv])
  };

  const afterClick = () => {
    setIsAdded(false);
    setText("Added!");
    setTimeout(() => setText("Add To Cart"), 2000);
  };
  const isCartItemExists = cart.items.some(
    (item) => item.id === "price_1ODiOrKNMdnS9NXCPUekfbgA"
  );
  const [mainImage, setMainImage] = React.useState(null);

  const handleImageClick = (imageUrl) => {
    setMainImage(imageUrl);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <motion.main
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <section class="py-40 bg-gray-50 ">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="grid grid-cols-1 mt-8 lg:grid-rows-1 gap-y-12 lg:grid-cols-5 lg:gap-y-16 lg:gap-x-12 xl:gap-x-16">
            <div class="lg:col-span-3 lg:row-end-1">
              <div class="lg:flex lg:items-start">
                <div class="lg:order-2 lg:ml-5">
                  <div class="overflow-hidden border-2 border-transparent rounded-lg">
                    <img
                      class="object-cover w-full h-full"
                      src={!mainImage ? data?.image : mainImage}
                      alt=""
                    />
                  </div>
                </div>

                <div class="w-full lg:w-32 mt-2.5 lg:mt-0 lg:flex-shrink-0 lg:order-1">
                  <div class="flex lg:flex-col items-stretch lg:flex-col lg:space-y-5 space-x-2.5 lg:space-x-0">
                    <button
                      type="button"
                      class="flex-1"
                      onClick={() => handleImageClick(data?.image)}
                    >
                      <div class="overflow-hidden border-2 border-gray-900 rounded-lg aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3">
                        <img
                          class="object-cover w-full h-full"
                          src={data?.image}
                          alt=""
                        />
                      </div>
                    </button>

                    <button
                      type="button"
                      class="flex-1"
                      onClick={() => handleImageClick(data?.image2)}
                    >
                      <div class="overflow-hidden border-2 border-transparent rounded-lg aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3">
                        <img
                          class="object-cover w-full h-full"
                          src={data?.image2}
                          alt=""
                        />
                      </div>
                    </button>

                    <button
                      type="button"
                      class="flex-1"
                      onClick={() => handleImageClick(data?.image3)}
                    >
                      <div class="overflow-hidden border-2 border-transparent rounded-lg aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3">
                        <img
                          class="object-cover w-full h-full"
                          src={data?.image3}
                          alt=""
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-2 lg:row-end-2 lg:row-span-2 flex flex-col ">
    <h1 class="text-2xl flex font-bold text-gray-900 sm:text-3xl">
        {data?.name}
    </h1>



{/* STARS */}
              <div class="flex items-center ">
                <div class="flex items-center space-x-px">
                  <svg
                    class="w-4 h-4 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
              </div>

              {/* END OF STARS */}

              <div class="flex items-center mt-4">
                <p class="text-3xl font-bold text-gray-900">
                  ${getPrice?.toFixed(2)}
                </p>
              </div>

              <div class="flex items-center  text-sm font-medium text-gray-500">
                <svg
                  class="w-4 h-4 mr-2.5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                Spend $150 get Free Shipping
              </div>

              <div hidden={data?.name === 'SeaMoss Wellness Bundle'} className="mt-4">
                <p
                >
                  Size:
                </p>
              </div>
              <RadioGroup
                value={selectedValue}
                onChange={handleRadioChange}
                row
                style={{ padding: "0px 0px 0px 0px" }}
                hidden={data?.name === 'SeaMoss Wellness Bundle'}
              >
                {data?.size.map((size, index) => (
                  <FormControlLabel
                    key={index}
                    value={size}
                    control={<Radio />}
                    label={size}
                  />
                ))}
              </RadioGroup>


              {data?.name === "SeaMoss Juice Cleanse" ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <Button onClick={handleOpen}>
                      {" "}
                      <span className="ml-1.5 mr-1 inline-block">
                        View Juice Flavors
                      </span>
                      <FiArrowUpRight className="mr-2 inline-block" />
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <p>
                          Juice Flavors:
                          
                          <ul className="space-y-3 mt-3">
                            <li>➢ Beet Pineapple Zing</li>
                            <li>➢ Green Twist</li>
                            <li>➢ Island Delight</li>
                            <li>➢ Watermelon Burst</li>
                          </ul>
                        </p>
                        <p>
                          **Our products do not contain any food
                          additives/preservatives to extend the shelf life. Our
                          juices are frozen right after prep to preserve its
                          flavor and nutritional value. Juices need to stay in
                          the freezer until the day of consumption. Consume 5
                          Juices Daily**
                        </p>
                      </Box>
                    </Modal>
                  </div>
                </Box>
              ) : null}
              <h2
                hidden={data?.name === "SeaMoss Bundle Collection" || data?.name === 'Ginger-Shot (Mucus Buster)' || data?.name === "Elderberry Syrup" || data?.name === 'Herbal Soursop Tea'}
                class=" text-base mt-4 font-bold text-gray-900"
              >
                {data?.name === "SeaMoss Juice Cleanse"
        ? <p>Benefits:</p>
        : data?.name === "SeaMoss Wellness Bundle" || data?.name === "Strawberry Blast Bundle Collection" || data?.name === "Citrus Journey Bundle Collection" || data?.name === "Cherry Walk Bundle Collection"
          ? <p>Contains:</p>
          : <p>Ingredients:</p>
      }
              </h2>
              <div class="space-y-3 flex flex-col lg:justify-start  text-base font-medium text-gray-600 list-disc list-inside">
                {data?.name === "Customized Herb Gel" && (
                  <>
                    <li>
                      ➢ Bladderwrack - Promotes good heart health, healthy
                      digestion, Aids in weight loss via increased metabolism,
                      reduces risk of coronary heart disease.
                    </li>
                    <li>
                      ➢ Burdock root - detoxifying the body, healing the liver,
                      preventing chronic disease, reducing inflammation,
                      boosting the immune system
                    </li>
                    <li>
                      ➢ Elderberry – Aids Boost immune system, helps fight off
                      cold and flu, high in vitamin C, the body's most abundant
                      antioxidant, helps reduce the risk of chronic disease
                    </li>
                    <li>
                      ➢ Ginger – Aids breaks down mucus, improves blood sugar,
                      aids in lowering cholesterol, reduces nausea
                    </li>
                    <li>
                      ➢ Sarsaprilla Root - Energy and Vitality, aids in
                      increasing Stamina and Libido, Iron support, Blood and
                      Liver Cleanser, helps tone Skin, Joint Health - Arthritis,
                      Gout
                    </li>
                  </>
                )}
                {data?.name === "Gold Sea Moss Gel" && (
                  <>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                  </>
                )}
                {data?.name === "Strawberry Sea Moss Gel" && (
                  <>
                    <li>➢ Strawberries & Lime Juice</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                  </>
                )}
                {data?.name === "Cherry Sea Moss Gel" && (
                  <>
                    <li>➢ Cherries & Limes</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                  </>
                )}
                {data?.name === "Strawberry Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Strawberries</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name ===
                  "Strawberry Pineapple Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Strawberries & Pineapples</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name ===
                  "Dragonfruit Lemon Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Dragonfruit & Lemon</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name ===
                  "Cherry Limeade Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Cherries & Limes</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name === "Lime Ginger Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Lime & Ginger</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name === "Lemon Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Lemon</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name === "Cherry Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Cherries</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name ===
                  "Strawberry Lemon Sea Moss-Infused Fruit Juice" && (
                  <>
                    <li>➢ Strawberries & Lemon</li>
                    <li>➢ Wildcrafted Sea Moss</li>
                    <li>➢ Natural Spring Water</li>
                    <li>➢ Agave Syrup - A Natural Sweetener</li>
                  </>
                )}
                {data?.name === "SeaMoss Juice Cleanse" && (
                  <>
                    <li>➢ Weight Loss</li>
                    <li>➢ Rid body of harmful toxins</li>
                    <li>➢ Improve Gut Health</li>
                    <li>➢ Stabilize Blood Sugar</li>
                    <li>➢ Strengthen Immune System</li>
                  </>
                )}
                {data?.name === "SeaMoss Wellness Bundle" && (
                  <>
                    <li>➢ 1 Ginger-Shot</li>
                    <li>➢ 2 SeaMoss Herbal Teas</li>
                    <li>➢ 1 Lime-Ginger Juice</li>
                    <li>➢ 1 8oz. Elderberry Sea Moss Gel</li>
                    <li>➢ 1 Elderberry Syrup</li>
                  </>
                )}
                {data?.name === "Strawberry Blast Bundle Collection" && (
                  <>
                    <li>➢ 1 Strawberry Sea Moss-Infused Fruit Juice</li>
                    <li>➢ 1 Strawberry Pineapple Sea Moss-Infused Fruit Juice</li>
                    <li>➢ 1 Strawberry Lemon Sea Moss-Infused Fruit Juice</li>
                    <li>➢ 1 8oz. Strawberry Sea Moss Gel</li>
                  </>
                )}
                {data?.name === "Citrus Journey Bundle Collection" && (
                  <>
                    <li> 1 Lemon Sea Moss-Infused Fruit Juice</li>
                    <li> 1 Dragonfruit Lemon Sea Moss-Infused Fruit Juice</li>
                    <li> 1 Cherry Lime Sea Moss-Infused Fruit Juice</li>
                    <li> 1 Lime Ginger Sea Moss-Infused Fruit Juice</li>
                    <li> 1 Strawberry Lemon Sea Moss-Infused Fruit Juice</li>
                    <li> 1 Ginger Shot</li>
                  </>
                )}
                {data?.name === "Cherry Walk Bundle Collection" && (
                  <>
                    <li>➢ 1 Cherry Sea Moss-Infused Fruit Juice</li>
                    <li>➢ 1 Cherry Lime Sea Moss-Infused Fruit Juice</li>
                    <li>➢ 8oz. Cherry Sea Moss Gel</li>
                  </>
                )}
                
              </div>



              <div class="flex items-center  mt-10">
                <Button
                  hidden={
                    data?.name === "SeaMoss Bundle Collection" ||
                    data?.name === "Customized Herb Gel"
                  }
                  style={{ backgroundColor: "green" }}
                  variant="contained"
                  onClick={(e) => handleClick(e)}
                  size="large"
                >
                  {/* {isAdded ? (
                    <BeatLoader
                      loading
                      color="white"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    text
                  )} */}
                  Add to cart
                </Button>
                <div className="flex flex-col gap-1 w-72 fixed top-20 right-2 z-50 pointer-events-none">
        <AnimatePresence>
          {notifications.map((n) => (
            <Notification removeNotif={removeNotif} {...n} key={n.id} />
          ))}
        </AnimatePresence>
      </div>
                {data?.name === "SeaMoss Bundle Collection" ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <p>
                      Introducing our Seamoss Bundle Variety Collection, with 8
                      delicious juices and 2 all-natural gels. Choose any 2
                      flavors from our selection of 9 for a unique and
                      refreshing experience. Don't miss out on trying them all!
                    </p>
                    {/* <textarea></textarea> */}
                    <BundleForm id={id} />
                  </Box>
                ) : null}

           
              </div>

             
            </div>
          </div>
        </div>

      </section>
    </motion.main>
  );
};

export default NewSingleProduct;
