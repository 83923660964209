import React from 'react'
import navLogo from '../../images/food.png';
import gb from '../../images/gb.png'
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
const NewFooter = () => {
  return (

<footer class="py-12 bg-gray-900 sm:py-16 lg:py-20">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="md:flex md:items-center md:justify-between">
            <h5 class="max-w-xl text-3xl font-bold text-white sm:text-4xl xl:text-5xl font-pj">Sea More in Life with SeaMos-Sista</h5>

            <div class="max-w-sm mt-8 md:mt-0">
                <ul class="space-y-7">
                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span class="ml-5 text-xl font-normal text-white font-pj"> Made in USA </span>
                    </li>

                    <li class="flex items-start">
                        <svg class="flex-shrink-0 w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                        <span class="ml-5 text-xl font-normal text-white font-pj"> seamossista@gmail.com</span>
                    </li>
                    <li className='flex gap-8 text-white'>
                        <a className='text-white' href='https://www.facebook.com/seamossistaLLC?_rdr' target='_blank' rel='noreferrer'>
                    <FaFacebook className='text-2xl'  />
                    </a>
                    <a className='text-white' href='https://www.instagram.com/Sea_Mos_Sista/' target='_blank' rel='noreferrer'>
                    <FaSquareInstagram className='text-2xl' />
                    </a>
                    </li>
                </ul>
            </div>
        </div>

        <hr class="mt-12 border-gray-600 md:mt-20" />

        <div class="mt-10 lg:flex lg:items-center lg:justify-between md:mt-16">
            <ul class="flex items-center space-x-6 sm:space-x-12">
                <li>
                    <a href="/" title="" class="text-lg font-medium text-white transition-all duration-200 font-pj hover:text-gray-300"> Home </a>
                </li>

                <li>
                    <a href="/shop" title="" class="text-lg font-medium text-white transition-all duration-200 font-pj hover:text-gray-300"> Shop </a>
                </li>

                <li>
                    <a href="/about-us" title="" class="text-lg font-medium text-white transition-all duration-200 font-pj hover:text-gray-300"> About Us </a>
                </li>

                <li>
                    <a href="/contact" title="" class="text-lg font-medium text-white transition-all duration-200 font-pj hover:text-gray-300"> Contact </a>
                </li>
            </ul>

            <p class="mt-8 text-lg font-normal text-white lg:mt-0 font-pj">© Copyright 2024, All Rights Reserved</p>
        </div>
    </div>
</footer>

  )
}

export default NewFooter