import React from 'react'
import men from '../images/heroimage final.png'
import women from '../images/bundle 3.png'
import { Button } from 'flowbite-react'
import { productsArray } from './Products/ProductsArray'
import strawberry from '../images/strwbrygel.png'
import lemon from '../images/llemon juice.png'
import cleanse from '../images/strawberrybundle.png'
import viewAll from '../images/seamoss bundle 2 gels.jpeg'
const Categories1 = () => {
    const gelProducts = productsArray[0].products.filter((product) =>
        product.name.toLowerCase().endsWith('gel')
      );
      
      const juiceProducts = productsArray[0].products.filter((product) =>
        product.name.toLowerCase().endsWith('juice')
      );
      
      const bundleProducts = productsArray[0].products.filter((product) =>
        product.name.toLowerCase().endsWith('collection')
      );
      
  return (

<section class="py-12 sm:py-16 lg:py-20 bg-gray-50">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="text-center sm:text-left">
            <h2 class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">Popular Categories</h2>
        </div>

        <div class="grid grid-cols-1 gap-4 mt-8 sm:mt-12 sm:grid-cols-2 lg:grid-cols-4">
            <div class="relative overflow-hidden group">
                <div class="absolute inset-0">
                    <img class="object-cover w-full h-full transition-all duration-300 group-hover:scale-125" src={strawberry} alt="" />
                    <div className="absolute inset-0 bg-black opacity-50"></div> 
                </div>

                <div class="relative mt-16 p-6 flex items-center flex-col justify-center">
                    <p class="text-lg font-bold text-white uppercase">Gels</p>
                    {/* <p class="mt-1.5 text-sm font-medium text-gray-900">{gelProducts.length} Products</p> */}

                    <div class="">
                        <a
                            href="/shop?productsToShow=gels"
                            title=""
                            class="inline-flex items-center justify-center px-3 py-2 text-xs font-medium text-white transition-all duration-200 bg-gray-900 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            role="button"
                        >
                            Shop Now
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="relative overflow-hidden group">
                <div class="absolute inset-0">
                    <img class="object-cover w-full h-full transition-all duration-300  group-hover:scale-125" src={lemon} alt="" />
                    <div className="absolute inset-0 bg-black opacity-50"></div> 
                </div>

                <div class="relative p-6 mt-16 flex items-center flex-col justify-center">
                    <p class="text-lg font-bold text-white uppercase">Juices</p>
                    {/* <p class="mt-1.5 text-sm font-medium text-gray-900">{juiceProducts.length} Products</p> */}

                    <div class="">
                        <a
                                 href="/shop?productsToShow=juices"
                            title=""
                            class="inline-flex items-center justify-center px-3 py-2 text-xs font-medium text-white transition-all duration-200 bg-gray-900 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            role="button"
                        >
                            Shop Now
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="relative overflow-hidden group">
                <div class="absolute inset-0">
                    <img class="object-cover w-full h-full transition-all duration-300  group-hover:scale-125" src={cleanse} alt="" />
                    <div className="absolute inset-0 bg-black opacity-50"></div> 
                </div>

                <div class="relative p-6 mt-16 flex items-center flex-col justify-center">
                    <p class="text-lg font-bold text-white uppercase">Bundles</p>
                    {/* <p class="mt-1.5 text-sm font-medium text-gray-900">{bundleProducts.length} Products</p> */}

                    <div class="">
                        <a
                                 href="/shop?productsToShow=bundles"
                            title=""
                            class="inline-flex items-center justify-center px-3 py-2 text-xs font-medium text-white transition-all duration-200 bg-gray-900 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            role="button"
                        >
                            Shop Now
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="relative overflow-hidden group">
                <div class="absolute inset-0">
                    <img class="object-cover w-full h-full transition-all duration-300  group-hover:scale-125" src={viewAll} alt="" />
                    <div className="absolute inset-0 bg-black opacity-50"></div> 
                </div>

                <div class="relative p-6 mt-16 flex items-center flex-col justify-center">
                    <p class="text-lg font-bold text-white uppercase">View All</p>
                    {/* <p class="mt-1.5 text-sm font-medium text-white">{productsArray[0].products.length} Products</p> */}

                    <div class="">
                        <a
                                 href="/shop"
                            title=""
                            class="inline-flex items-center justify-center px-3 py-2 text-xs font-medium text-white transition-all duration-200 bg-gray-900 border border-transparent rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            role="button"
                        >
                            Shop Now
                            <span class="absolute inset-0" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
            </div>

         
        </div>
    </div>
</section>

  )
}

export default Categories1