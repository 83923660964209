import { Button } from 'flowbite-react'
import React, { useContext } from 'react'
import bk from '../../images/newbk.jpeg'
import { CartContext } from '../Cart/CartContext';
const NewHero = () => {
    const width = 414
  return (
    <React.Fragment>
<div class="relative lg:h-screen  bg-black grid items-center py-40">


    <div class="absolute inset-0">
    <div class="bg-black bg-opacity-50 absolute inset-0 "/>
        <img loading="lazy" class="object-cover object-top w-full h-full" src={bk} alt="" />
    </div>

    <div class="relative">
        <div class="px-6 mx-auto sm:px-6 lg:px-12 max-w-7xl">
            <div class="text-center w-4/5 mx-auto">
                
                <p class="lg:mt-6 tracking-tighter text-white">
                    <span style={{fontFamily: 'Poppins'}} class="font-sans font-normal text-5xl lg:text-8xl ">Sea More in Life <br></br>with<br/> SeaMos-Sista</span>      
                </p>
                <p class="w-4/5 mx-auto font-sans text-base text-xl font-normal leading-7 text-white text-opacity-70">Our mission is simple: to empower individuals on their health journey by showcasing the incredible benefits of sea moss. 🌱</p>
               

                <div class="flex items-center mt-5 space-x-3 sm:space-x-4">

                    <Button className="
                           mx-auto
                            lg:w-2/5
                            py-3
                            font-sans
                            w-full
                            text-xl
                            font-semibold
                            transition-all
                            duration-200
                            border-2 border-transparent
                            rounded-full
                            sm:leading-8
                            bg-[#2196F3]
                            
                            text-white
                            hover:bg-opacity-90
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-offset-secondary
                        "
                        role="button" gradientDuoTone="cyanToBlue"  href="/shop">SHOP NOW</Button>
                </div>
            </div>
        </div>
    </div>
</div>








</React.Fragment>
  )
}

export default NewHero