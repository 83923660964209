import React from 'react'
import bundle from '../images/bundle 3.png'
import bundle1 from '../images/bundle.png'
import { motion } from 'framer-motion'
import NewProductInfo from '../components/ProductInfo/NewProductInfo'
import NewProduct2 from '../components/Products/NewProduct2'
const LearnMore = () => {
  return (
    <motion.main exit={{opacity:0}} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: 'easeOut'}}>
    <section className=" bg-[#efefef] py-24">
  <div className="w-[85%] md:w-4/5 mx-auto text-center lg:pt-12 mt-6">
    <span className="badgeBoxShadow uppercase rounded-2xl text-[#6A65FF] text-sm md:text-base font-medium leading-loose  tracking-widest px-5 py-3 badgeGradientText text-transparent bg-clip-text">
      New arrivals
    </span>
    <h1 className="text-4xl md:text-5xl lg:text-6xl mb-4 font-semibold text-iso-gray-dark pt-3 mt-2">
      Shop our Newest{' '}
      <br />
      <span className="text-gradient ">
        Products!
      </span>
    </h1>
    <p className="text-iso-gray-light text-lg md:text-xl pt-4 font-normal">
      Powerful product and growth analytics to help you convert, engage, and retain more users.
    </p>
  </div>

  <section className=" mx-auto w-4/5 mx-auto lg:grid lg:gap-8 lg:grid-cols-2">
    <div className=" pb-12 m-auto">
      <svg
        className="badgeBoxShadow rounded-lg gradientIcon1 px-2 py-2"
        fill="currentColor"
        height="40"
        viewBox="0 0 16 16"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
      </svg>
      <h1 className="w-4/5 text-3xl md:text-4xl lg:text-5xl leading-tight pt-4 tracking-tight mb-4 font-semibold  text-iso-gray-dark">
        Try our newest flavors!
      </h1>
      <p className="mb-8 leading-relaxed font-normal mt-4 text-iso-gray text-lg w-4/5 ">
        With our calendar app, you can quickly create notes and tasks to help you plan and organize your tasks projects.
      </p>
      <svg
        aria-hidden="true"
        focusable="false"
        style={{
          height: '0',
          position: 'absolute',
          width: '0'
        }}
      >
        <linearGradient
          id="gradient1"
          x2="1"
          y2="1"
        >
          <stop
            offset="0%"
            stopColor="#7A76FC"
          />
          <stop
            offset="100%"
            stopColor="#61A4EB"
          />
        </linearGradient>
      </svg>
      <ul className="font-normal text-iso-gray flex flex-col flex-wrap space-y-4 text-lg">
        <li className="flex items-center space-x-2">
          <svg
            className="gradientIcon1"
            fill="currentColor"
            height="24"
            viewBox="0 0 16 16"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
          <span>
            Quickly create notes and tasks to help you plan
          </span>
        </li>
        <li className="flex items-center space-x-2">
          <svg
            className="gradientIcon1"
            fill="currentColor"
            height="24"
            viewBox="0 0 16 16"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
          <span>
            Quickly create notes and tasks to help you plan
          </span>
        </li>
        <li className="flex items-center space-x-2">
          <svg
            className="gradientIcon1"
            fill="currentColor"
            height="24"
            viewBox="0 0 16 16"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
          <span>
            Quickly create notes and tasks to help you plan
          </span>
        </li>
      </ul>
    </div>
    <div className=" mx-auto rounded-3xl h-5/6 pt-8 mt-4">
      <img
        alt="hero"
        className="object-cover object-center rounded-3xl w-full h-full"
        data-nimg="1"
        decoding="async"
        height="500"
        loading="lazy"
        src={bundle}
        style={{
          color: 'transparent'
        }}
        width="500"
      />
    </div>
  </section>



  <section className=" mx-auto w-4/5 mx-auto lg:grid lg:gap-8 lg:grid-cols-2 ">
    <div className=" mx-auto rounded-3xl h-5/6 pt-8 mt-4">
      <img
        alt="hero"
        className="object-cover object-center rounded-3xl w-full h-full"
        data-nimg="1"
        decoding="async"
        height="500"
        loading="lazy"
        src={bundle1}
        style={{
          color: 'transparent'
        }}
        width="500"
      />
    </div>
    <div className="md:ml-12 pt-10 lg:mt-36">
      <svg
        className="badgeBoxShadow rounded-lg gradientIcon1 px-2 py-2"
        fill="currentColor"
        height="40"
        viewBox="0 0 16 16"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
      </svg>
      <h1 className="w-4/5 text-4xl lg:text-5xl leading-tight tracking-tight mb-4 pt-4 font-semibold text-iso-gray-dark">
        Sick and Wellness Bundle
      </h1>
      <p className="mb-8 font-normal leading-relaxed  mt-4 text-iso-gray text-lg ">
        We also have lots of integrations with popular apps, so that you can organize and streamline your workflow.
      </p>
    </div>
  </section>

</section>
<NewProductInfo />
<NewProduct2 />
</motion.main>
  )
}

export default LearnMore