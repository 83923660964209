import React from 'react'
import Hero from '../components/Hero/Hero'
import Navbar from '../components/Navbar/Navbar'
import WhyBuy1 from '../components/WhyBuy/WhyBuy1'
import ProductInfo from '../components/ProductInfo/ProductInfo'
import Products from '../components/Products/Products'
import HowToUse from '../components/HowToUse/HowToUse'
import Reviews from '../components/Reviews/Reviews'
import Footer from '../components/Footer/Footer'
import AboutUs from '../components/AboutUs/AboutUs'
import Contact from '../components/Contact/Contact'
import NewHero from '../components/Hero/NewHero'
import LogoGrid from '../components/WhyBuy/LogoGrid'
import NewProducts from '../components/Products/NewProducts'
import NewProductInfo from '../components/ProductInfo/NewProductInfo'
import NewReviews from '../components/Reviews/NewReviews'
import NewFAQ from '../components/HowToUse/NewFAQ'
import NewContact from '../components/Contact/NewContact'
import NewAboutUs from '../components/AboutUs/NewAboutUs'
import NewProduct2 from '../components/Products/NewProduct2'
import Incentives from '../components/Incentives'
import Categories from '../components/Categories 2'
import { motion } from 'framer-motion'
import LogoGrid1 from '../components/LogoGrid1'
const Home = () => {

  return (
    <React.Fragment>
            <motion.main exit={{opacity:0}} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: 'easeOut'}}>
         {/* <Hero /> */}
         <NewHero />
         {/* <WhyBuy1 /> */}
         {/* <LogoGrid /> */}
      {/* <Products /> */}
   

      <NewProducts />
      <Incentives />
      <NewProduct2 />
      <NewProductInfo />
      <Categories />
      {/* <NewAboutUs /> */}
      {/* <ProductInfo /> */}
      {/* <NewReviews /> */}
     
      {/* <Reviews /> */}
     
      {/* <HowToUse /> */}
      <NewFAQ />

      {/* <Contact /> */}
      <NewContact />
      </motion.main>
    </React.Fragment>
  )
}

export default Home