import React from 'react'
import ls from '../../images/hj.jpeg'
const NewProduct2 = () => {
  return (
<section class="pb-24 py-20 bg-[#EFEFEF]">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
            <p class="text-sm font-semibold tracking-widest text-blue-600 uppercase">health is wealth</p>

            <h2 class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">Let sea moss help you on your health journey !</h2>
        </div>

        <div class="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
            <div class="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-6">
                <div class="flex items-start">
                    <svg class="flex-shrink-0 text-green-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black">Healthy Mucus Membrane Support</h3>
                        <p class="mt-3 text-base text-gray-600">Sea moss helps rid the body of excess mucus, making it a natural decongestant.</p>
                    </div>
                </div>

                <div class="flex items-start">
                    <svg class="flex-shrink-0 text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black">Gut-Booster</h3>
                        <p class="mt-3 text-base text-gray-600">Sea moss soothes the digestive tract, alleviating acid reflux and promoting gut health. Plus, its fiber content keeps things moving smoothly.</p>
                    </div>
                </div>

                <div class="flex items-start">
                    <svg class="flex-shrink-0 text-red-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black">Elevate your Mood</h3>
                        <p class="mt-3 text-base text-gray-600">Potassium-rich sea moss supports mental and emotional well-being. It’s like a gentle hug for your mind.</p>
                    </div>
                </div>
            </div>

            <div class="lg:col-span-3">
                <img loading="lazy" class="w-full rounded-lg shadow-xl" src={ls} alt="" />
            </div>
        </div>
    </div>
</section>

  )
}

export default NewProduct2