import { Typography } from '@mui/material'
import React from 'react'
import { MDBBtn, MDBValidation, MDBValidationItem, MDBInput, MDBTextArea } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router'
const Questions = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
    let navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries());
        try {
          const response = await fetch('http://localhost:4000/submit-form', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          });
      
          if (response.ok) {
            console.log('Email sent successfully');
            navigate('/')
          } else {
            console.error('Failed to send email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
      
  return (
    <div className='p_container' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px'}}>
        <Typography sx={{fontFamily: 'Bebas Neue', color: '#4A6B68'}} className='text-center' variant={isMobile ? 'h2' : 'h1'}>Questions?</Typography>
        <Typography className='text-center' variant={isMobile ? 'h6' : 'h5'} sx={{padding: isMobile ? '30px' : '0px',pb: 5, color: '#557174', fontWeight: 'normal', fontFamily: 'Bebas Neue'}}>Leave us a message, and we will reach out as soon as we can!</Typography>
        <MDBValidation noValidate id='form' className='text-center' style={{ width: '100%', maxWidth: '600px', padding: isMobile ? '30px' : '0px' }} onSubmit={handleSubmit}>
      <MDBValidationItem invalid feedback='Please provide your name.'>
        <MDBInput label='Name' name='name' v-model='name' wrapperClass='mb-4' required />
      </MDBValidationItem>

      <MDBValidationItem invalid feedback='Please provide your email.'>
        <MDBInput type='email' name='email' label='Email address' v-model='email' wrapperClass='mb-4' required />
      </MDBValidationItem>

      <MDBValidationItem invalid feedback='Please provide mail subject.'>
        <MDBInput label='Subject' name='subject' v-model='subject' wrapperClass='mb-4' required />
      </MDBValidationItem>

      <MDBValidationItem invalid feedback='Please provide a message text.'>
        <MDBTextArea style={{height: '150px'}} wrapperClass='mb-4' name='message' label='Message' required />
      </MDBValidationItem>

      {/* <MDBValidationItem feedback=''>
        <MDBCheckbox wrapperClass='d-flex justify-content-center' label='Send me copy' />
      </MDBValidationItem> */}

      <MDBBtn type='submit'  block className='my-4'   
      style={{
    backgroundColor: '#77976C',
    color: 'white',
    width: '50%',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    // padding: '10px 20px',
    borderRadius: '40px',
    marginTop: '15px',
  }}
  >
        Send
      </MDBBtn>
    </MDBValidation>
        </div>
  )
}

export default Questions