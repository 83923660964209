import React from 'react'
import us from '../../images/aboutus.JPG'
import { motion } from 'framer-motion'
import axios from 'axios';
import { Button } from 'flowbite-react'
import SteppedProgress from '../Progress';
const NewAboutUs = () => {
    const [firstName, setFirstName] = React.useState('');
const [email, setEmail] = React.useState('');
const [lastName, setLastName] = React.useState('');
const [companyName, setCompanyName] = React.useState('');
const [message, setMessage] = React.useState('');


const handleSubmit = (e) => {
  e.preventDefault();
   // Log the inputted responses
   console.log('First Name:', firstName);
   console.log('Last Name:', lastName);
   console.log('Email:', email);
   console.log('Company Name:', companyName);
   console.log('Message:', message);
  // Handle form submission
  const formData = {
    firstName,
    lastName,
    email,
    companyName,
    message,
  };

  // Make a POST request to the Pabbly webhook URL
  axios.post('https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTZiMDYzMTA0MzA1MjZhNTUzNDUxMzMi_pc', formData)
    .then((response) => {
      // Handle the response from the Pabbly webhook if needed
      console.log(response.data);
    })
    .catch((error) => {
      // Handle any errors that occur during the request
      console.error(error);
    });
};
  return (
    <motion.main exit={{opacity:0}} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: 'easeOut'}}>
<section class="py-40 bg-[#efefef]">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-md mx-auto text-center">
            <h2 class="text-5xl font-bold text-gray-900 capitalize">Our Mission</h2>
        </div>

        <div class="grid grid-cols-1 px-12 mt-12 sm:mt-16  sm:gap-x-10 sm:px-0 ">
            <div class="xl:flex mx-auto xl:justify-center">     
                <img class="object-cover w-auto h-80 rounded-xl mx-auto" src={us} alt="" />
                <div class="flex flex-col justify-between flex-1 mt-6 xl:mt-0 xl:ml-10">
                    <div className=''>
                        <p class="text-lg font-bold text-gray-900">Danielle Lindsey</p>
                        <p class="mt-1 text-sm font-medium text-gray-500">Founder, Owner of SeaMos-Sista LLC.</p>
                  
                        <p class="text-md font-medium text-gray-600 leading-8">SeaMos-Sista was founded as a means of achieving personal healing. During this process, I received a spiritual revelation that everything needed for healing the human body is accessible on this earth - from herbs to natural, unprocessed food. People residing in the islands rely on sea moss and exotic fruits for sustenance, resulting in significantly lower rates of illness when compared to America. Our goal at SeaMos-Sista is to introduce this time-honored approach to wellness to the United States by promoting a dietary and lifestyle shift. We place tremendous value on health and individual well-being, encouraging a holistic approach to living as a means of achieving our ultimate goal: a thriving society that prioritizes wellness.</p>
                       <div className='gap-4 flex flex-col md:flex-row'>
                        <a
                            href="https://m.facebook.com/seamossistaLLC"
                            target='_blank'
                            rel='noreferrer'
                            title=""
                            class="inline-flex items-center justify-center flex-1 px-5 py-3 text-xs font-bold tracking-widest text-white uppercase transition-all duration-200 border border-transparent rounded-lg shrink-0 bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 hover:bg-sky-500"
                            role="button"
                        >
                            Like Us on Facebook
                        </a>
                        <a
                            href="https://www.instagram.com/Sea_Mos_Sista/"
                            target='_blank'
                            rel='noreferrer'
                            title=""
                            class="inline-flex items-center justify-center flex-1 px-5 py-3 text-xs font-bold tracking-widest text-white uppercase transition-all duration-200 border border-transparent rounded-lg shrink-0 bg-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 hover:bg-sky-500"
                            role="button"
                        >
                            Like Us on Instagram
                        </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

</motion.main>

  )
}

export default NewAboutUs