import React from 'react'
import NewContact from '../components/Contact/NewContact';
const Contact1 = () => {
  return (
    <div className='py-10'>
        <NewContact />
    </div>
  )
}

export default Contact1;