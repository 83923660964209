import React from "react";
import { productsArray } from "../components/Products/ProductsArray";
import { Button } from "flowbite-react";
import { motion } from "framer-motion";
import { FiChevronDown } from "react-icons/fi";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  Divider,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
const Shop = () => {
  const gelProducts = productsArray[0].products.filter((product) =>
    product.name.toLowerCase().endsWith("gel")
  );

  const juiceProducts = productsArray[0].products.filter((product) =>
    product.name.toLowerCase().endsWith("juice")
  );

  const bundleProducts = productsArray[0].products.filter(
    (product) =>
      product.name.toLowerCase().endsWith("collection") ||
      product.name === "SeaMoss Wellness Bundle" ||
      product.name === "SeaMoss Juice Cleanse"
  );

  const wellnessProducts = productsArray[0].products.filter(
    (product) =>
      product.name === "Ginger-Shot (Mucus Buster)" ||
      product.name === "Elderberry Syrup" ||
      product.name === "SeaMoss Wellness Bundle" ||
      product.name === "Herbal Soursop Tea"
  );

  const lowestToHighest = [...productsArray[0].products].sort(
    (a, b) => a.price[0] - b.price[0]
  );

  const highestToLowest = [...productsArray[0].products].sort(
    (a, b) => b.price[0] - a.price[0]
  );
  console.log(highestToLowest);
  const filterBySize = (size) => {
    return productsArray[0].products.filter((product) =>
      product.size[0].includes(size)
    );
  };

  const productsContainerRef = useRef(null);

  const [productsToShow, setProductsToShow] = React.useState("featured");

  const [productsToRender, setProductsToRender] = React.useState([]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const productsToShowParam = urlParams.get("productsToShow");

    if (productsToShowParam) {
      setProductsToShow(productsToShowParam);
    }
  }, []);

  React.useEffect(() => {
    let productsToRender = [];

    if (productsToShow === "gels") {
      productsToRender = gelProducts;
    } else if (productsToShow === "juices") {
      productsToRender = juiceProducts;
    } else if (productsToShow === "bundles") {
      productsToRender = bundleProducts;
    } else if (productsToShow === "wellness") {
      productsToRender = wellnessProducts;
    } else if (productsToShow === "low") {
      productsToRender = lowestToHighest;
    } else if (productsToShow === "high") {
      productsToRender = highestToLowest;
    } else {
      productsToRender = productsArray[0].products;
    }

    setProductsToRender(productsToRender);
  }, [productsToShow]);

  // Rest of the child component code

  const handleRadioChange = (event) => {
    setProductsToShow(event.target.value);

    if (window.innerWidth < 1024) {
      productsContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const Question = ({ title, children, defaultOpen = false }) => {
    const [open, setOpen] = React.useState(defaultOpen);

    return (
      <motion.div
        animate={open ? "open" : "closed"}
        className="border-b-[1px] border-b-slate-300"
      >
        <button
          onClick={() => setOpen((pv) => !pv)}
          className="py-6 w-full flex items-center justify-between gap-4"
        >
          <motion.span
            variants={{
              open: {
                color: "rgba(3, 6, 23, 0)",
              },
              closed: {
                color: "rgba(3, 6, 23, 1)",
              },
            }}
            className="text-lg mx-auto  font-medium text-center bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text"
          >
            {title}
          </motion.span>
          <motion.span
            variants={{
              open: {
                rotate: "180deg",
                color: "rgb(124 58 237)",
              },
              closed: {
                rotate: "0deg",
                color: "#030617",
              },
            }}
          >
            <FiChevronDown className="text-2xl" />
          </motion.span>
        </button>
        <motion.div
          initial={false}
          animate={{
            height: open ? "fit-content" : "0px",
            marginBottom: open ? "24px" : "0px",
          }}
          className="overflow-hidden text-slate-600"
        >
          {children}
        </motion.div>
      </motion.div>
    );
  };
  return (
    <motion.main
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
    >
      <section class="py-24 bg-gray-50 ">
        <div class="px-4 mx-auto sm:px-6 lg:px-16 max-w-7xl">
          <div class="grid grid-cols-1 mt-6 md:mt-10 lg:grid-cols-4 gap-x-8 gap-y-10">
            <div class=" lg:block">
              <div class="text-center sm:max-w-md">
                <h1 class="text-xl font-bold text-gray-900 sm:text-3xl">
                  Our Products
                </h1>
              </div>

              <hr class=" border-gray-200" />

              <div class="flow-root ">
                <div class="-my-6 divide-y divide-gray-200">
                  <div class="py-6 space-y-7">
                    <Question title="CATEGORY" defaultOpen>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="featured"
                          name="radio-buttons-group"
                          value={productsToShow}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="featured"
                            control={<Radio />}
                            label="All Items"
                          />
                          <FormControlLabel
                            value="gels"
                            control={<Radio />}
                            label="Sea Moss Gels"
                          />
                          <FormControlLabel
                            value="juices"
                            control={<Radio />}
                            label="Sea Moss Juices"
                          />
                          <FormControlLabel
                            value="bundles"
                            control={<Radio />}
                            label="Sea Moss Bundles"
                          />
                          <FormControlLabel
                            value="wellness"
                            control={<Radio />}
                            label="Wellness Collection"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Question>
                    <Question title="PRICE" defaultOpen>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={productsToShow}
                          onChange={handleRadioChange}
                        >
                             <FormControlLabel
                            value="high"
                            control={<Radio />}
                            label="Price, Low To High"
                          />
                          <FormControlLabel
                            value="low"
                            control={<Radio />}
                            label="Price, High To Low"
                          />
                       
                        </RadioGroup>
                      </FormControl>
                    </Question>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-3">
              <div class=" rounded-md h-full">
                <section class="">
                  <div class="">
                    <div
                      ref={productsContainerRef}
                      class="grid grid-cols-1 gap-4 mt-8 sm:mt-12 sm:gap-6 lg:grid-cols-3"
                    >
                      {productsToRender.length > 0 ? (
                        productsToRender.reverse().map((obj, idx) => {
                          const pricesArray = obj?.id.split(",");
                          let test = pricesArray[0];
                          console.log(pricesArray[0]);
                          return (
                            <div
                              key={idx}
                              class="relative bg-white border border-gray-200 rounded-xl "
                            >
                              <div className="relative">
                              <div class=" aspect-w-1 aspect-h-1">
                                <img
                                  loading="eager"
                                  class="object-cover p-4 w-full h-full transition-all duration-300 group-hover:scale-125"
                                  src={obj?.image}
                                  alt=""
                                />
                              </div>
                              <div class="px-6">
                                <p class="text-xs font-bold tracking-wide text-gray-400 uppercase">
                                  SEAMOS-SISTA
                                </p>
                                <h3 class="text-sm sm:text-base font-bold text-gray-900 flex-1 mt-2.5">
                                  <a
                                    href={`/products/${test}`}
                                    className="text-[#2196F3]"
                                    title=""
                                  >
                                    {obj?.name}
                                    <span
                                      class="absolute inset-0"
                                      aria-hidden="true"
                                    ></span>
                                  </a>
                                </h3>
                                {/* <p class="text-sm sm:text-base text-gray-400 font-bold mt-2.5">
                                  Starting at: ${obj?.price[0]}
                                </p>
                                <Button
                                  href={`/products/${test}`}
                                  gradientDuoTone="cyanToBlue"
                                >
                                  Shop Now
                                </Button> */}
                                         <div class="  ">
                    <div>
                    <p class="text-sm sm:text-base text-gray-400 font-bold mt-2.5">
                                  Starting at: ${obj?.price[0].toFixed(2)}
                                </p>
                    <Button
                                  href={`/products/${test}`}
                                  gradientDuoTone="cyanToBlue"
                                >
                                  Shop Now
                                </Button>

      </div>
                </div>
                              </div>
                              </div>
                              <br />
                            </div>
                          );
                        })
                      ) : (
                        <p>No Products Available</p>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.main>
  );
};

export default Shop;
