import React from 'react'
import lucia from '../../images/stlucia.jpeg'
import StackedCardInfo from './Stacked'
const NewProductInfo = () => {
  return (
//     <section class="py-12 bg-[#C5E0F7] sm:py-16 lg:py-20 xl:py-24">
//     <div class="px-4 mx-auto sm:px-6 lg:px-8 w-4/5 py-4">
//         <StackedCardInfo />
//     </div>
// </section>
<section class="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl text-center">
        <div class="max-w-3xl mx-auto text-center">
            <h2 class="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Customers Love SeaMos-Sista!</h2>
        </div>

        <div class="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 sm:mt-16 lg:grid-cols-3 lg:max-w-none">
            <div class="bg-gray-100 rounded-2xl xl:-rotate-2">
                <div class="p-8">
                    <blockquote>
                        <p class="text-xl font-medium leading-9 text-gray-900">"All of these products are phenomenal!! The natural benefits of these wonderful products are incredible, but let's talk about the taste! Each flavor is delicious and refreshing!! Truly a wondrous product!"</p>
                    </blockquote>
                    <p class="mt-6 text-base font-semibold text-gray-900">Paula K.</p>
                 
                   
                </div>
            </div>

            <div class="bg-gray-100 rounded-2xl xl:rotate-2">
                <div class="p-8">
                    <blockquote>
                        <p class="text-xl font-medium leading-9 text-gray-900">"Tried this product a few weeks ago, and I have to admit I am impressed. The sea moss drinks are incredible, and there are health benefits to drinking them!!!"</p>
                    </blockquote>
                    <p class="mt-6 text-base font-semibold text-gray-900">Enrique G.</p>
                   
                   
                </div>
            </div>

            <div class="bg-gray-100 rounded-2xl xl:-rotate-2">
                <div class="p-8">
                    <blockquote>
                        <p class="text-xl font-medium leading-9 text-gray-900">"Every product I have tried has been exceptionally good. I would recommend this to anyone!"</p>
                    </blockquote>
                    <p class="mt-6 text-base font-semibold text-gray-900">Endiga G.</p>
                   
                 
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default NewProductInfo