import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";

const NewFAQ = () => {
  return (
    <div className="px-4 py-20 pb-40 bg-[#C5E0F7]">
      <div className="max-w-3xl mx-auto">
        <h2 class="text-3xl text-center font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
          Frequently Asked Questions
        </h2>
        <Question title="Where is our sea moss sourced?" defaultOpen>
          <p>
          Our Sea Moss grows along the rocky coast of the Atlantic ocean, sourced from the pristine waters of the Caribbean Sea surrounding Jamaica 🇯🇲 & St. Lucia 🇱🇨
          </p>
        </Question>
        <Question title="What are the health benefits of sea moss?">
          <p>
          Sea moss is rich in vitamins, minerals, and antioxidants that are beneficial for human health. It can support immune system function, promote digestion, and improve skin and hair health.
          </p>
        </Question>
        <Question title="What is the difference between irish moss and sea moss?">
          <p>
          Our Sea Moss grows in warm, tropical oceans and has thick, stick-like branches. Irish moss also known as Chondrus Crispus grows in colder, northern oceans and has flat curly leaves. Both are part of the red algae family which makes them very similar in nutritional value.
          </p>
        </Question>
        <Question title="How do I use sea moss?">
          <p>
          In its gel form, Sea Moss can be consumed on its own or added to your favorite smoothies, protein shakes, teas and juices. Due to its versatile texture and minimal taste, Sea Moss Gel can even be used as a salad dressing, dip for fruit, face mask, hair mask or as a vegan thickening agent when cooking soups and stews.
          </p>
        </Question>
        <Question title="What is the recommended dosage? Is it the same for adults and children?">
          <p>
          Sea Moss is an all natural herb that can be consumed by both, adults and children. As with all things, it should be consumed in moderation. We recommend consuming 1-2 tablespoons of Sea Moss Gel per day. Please note: Before using any supplements, it is always advisable to consult with your own health care provider and/or nutritionist. Individual results may vary, dependent on your health condition, diet and any other supplements or medications that you may be taking.
          </p>
        </Question>
      </div>
    </div>
  );
};

const Question = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className="border-b-[1px] border-b-slate-300"
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="py-6 w-full flex items-center justify-between gap-4"
      >
        <motion.span
          variants={{
            open: {
              color: "rgba(3, 6, 23, 0)",
            },
            closed: {
              color: "rgba(3, 6, 23, 1)",
            },
          }}
          className="text-lg font-medium text-left bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text"
        >
          {title}
        </motion.span>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "rgb(124 58 237)",
            },
            closed: {
              rotate: "0deg",
              color: "#030617",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? "fit-content" : "0px",
          marginBottom: open ? "24px" : "0px",
        }}
        className="overflow-hidden text-slate-600"
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default NewFAQ;