import React from 'react'
import gluten from '../images/Organic. Wildcrafted. Sea Moss. (11).png'
import natural from '../images/Organic. Wildcrafted. Sea Moss. (13).png'
import gmo from '../images/Organic. Wildcrafted. Sea Moss. (6).png'
import dog from '../images/super.webp'
const Incentives = () => {
  return (
    <section  class="bg-[#ffffff]  h-fit py-20">

    <div class="px-16 mx-auto max-w-9xl ">
        
        <div class="grid max-w-lg grid-cols-1 mx-auto gap-y-12 gap-x-14 lg:grid-cols-3 lg:max-w-none">
            
            <div class="flex flex-col items-center text-center border-5 rounded-xl p-4">
                <img className='w-20 h-11' src={gluten} alt='' />
                <div class="">
                    <h3 class="text-2xl font-bold text-gray-900">Health Benefits</h3>
                    <p class="mt-3 text-lg text-base font-normal text-gray-900"> Sea moss is a nutrient-rich superfood containing essential minerals, vitamins, and antioxidants. </p>
                </div>
            </div>

            <div class="flex flex-col items-center text-center border-5 rounded-xl p-4">
            <img className='w-20 h-11' src={gluten} alt='' />
                <div class="">
                    <h3 class="text-2xl font-bold text-gray-900">Sustainable Sourcing</h3>
                    <p class="mt-3 text-lg text-base font-normal text-gray-900">We prioritize responsible harvesting practices to ensure a continuous supply of sea moss while minimizing environmental impact. </p>
                </div>
            </div>

            <div class="flex flex-col items-center text-center border-5 rounded-xl p-4">
            <img className='w-20 h-11' src={gluten} alt='' />
                <div class="">
                    <h3 class="text-2xl font-bold text-gray-900">Quality and Variety</h3>
                    <p class="mt-3 text-lg text-base font-normal text-gray-900">We offer a range of sea moss products (raw, gel, capsules, teas, skincare) to cater to diverse preferences. </p>
                </div>
            </div>
        </div>
    </div>
</section>

  )
}

export default Incentives