import React, { useState } from 'react'
import logo from '../../images/huge.png'
import { Button } from 'flowbite-react'
import axios from 'axios'
import SteppedProgress from '../Progress'
import { motion } from 'framer-motion'
const NewContact = () => {

    return (
      <motion.main exit={{opacity:0}} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: 'easeOut'}}>
  <section className="bg-[#ffffff] p-4">
    <div className="lg:w-4/5 w-full mx-auto grid grid-cols-1 lg:grid-cols-2 gap-24 py-16 md:py-28 text-center md:text-left">
      <div>
        <h1 className="text-4xl tracking-widest font-semibold text-[#060C3C]">
          Talk to the{' '}
          <span className="tracking-widest text-[#007EFF]">
            Founders
          </span>
        </h1>
        <p className="text-[#56687B] text-base pt-4 font-normal">
          If you have any queries, be sure to let us know through our contact methods below. 
        </p>
        <p className="text-[#56687B] text-base font-normal">
          Phone: 260.207.4784
        </p>
        <p className="text-[#56687B] text-base  font-normal">
          Email: seamossista@gmail.com
        </p>
        <h3 className="text-[#232932] text-lg pt-4 font-semibold">
          Tyrell & Danielle Lindsey
        </h3>
        <p className="text-[#56687B] text-base font-normal">
         Founders, SeaMos-Sista LLC.
        </p>
        <img
        loading="lazy"
          className="rounded-xl object-contain w-50 mx-auto"
          src={logo}
          alt=''
        />
        {/* PUT THE ICONS AND LINKS TO CONTACT HERE */}
  
      </div>
     
      <SteppedProgress />
    </div>
  </section>
  </motion.main>
  
    )
  }

export default NewContact