import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import axios from 'axios';
import {Button} from 'flowbite-react';
import emailjs from '@emailjs/browser';
import logo from '../images/food.png'
import { useForm } from "react-hook-form";
import { Alert } from "@mui/material";
const SteppedProgress = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
   })
const [complete, setComplete] = useState(false);
  const sendEmail = () => {

    const htmlContent = (
      <div className="py-40" style={{ backgroundColor: '#ffffff', fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif' }}>
        <table align="center" width="100%" role="presentation" cellSpacing="0" cellPadding="0" border="0" style={{ maxWidth: '37.5em', margin: '0 auto', padding: '20px 0 48px' }}>
          <tbody>
            <tr style={{ width: '100%' }}>
              <td>
                <img alt="E&E Media" src={logo} width="auto" height="auto" style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', margin: '0 auto' }} />
                <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Thank you for contacting us through our website! We appreciate your inquiry and appreciate the time you took to reach out.

We will be in touch with you shortly to address your question, concern, or comment. Our team is dedicated to providing you with excellent support and assistance.

Thank you for your patience and understanding. We look forward to connecting with you soon!</p>
                <table align="center" width="100%" style={{ textAlign: 'center' }} border="0" cellPadding="0" cellSpacing="0" role="presentation">
                  <tbody>
                    <tr>
                      <td>
                        <a href="https://calendly.com/enriquemgreene/e-e-media-llc-service-consultation" target="_blank" rel="noreferrer" style={{ backgroundColor: '#5F51E8', borderRadius: '3px', color: '#fff', fontSize: '16px', textDecoration: 'none', textAlign: 'center', display: 'inline-block', lineHeight: '100%', maxWidth: '100%', padding: '12px 12px 12px 12px' }}>
                          <span>{/* <!--[if mso]><i style="letter-spacing: 12px;mso-font-width:-100%;mso-text-raise: 18;" hidden>&nbsp;</i><![endif]--> */}</span>
                          <span style={{ display: 'inline-block', lineHeight: '120%', maxWidth: '100%', msoPaddingAlt: '0px', msoTextRaise: '9px' }}>Get started</span>
                          <span>{/* <!--[if mso]><i style="letter-spacing: 12px;mso-font-width:-100%" hidden>&nbsp;</i><![endif]--> */}</span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ fontSize: '16px', lineHeight: '26px', margin: '16px 0' }}>Best regards,<br />
Danielle Lindsey
Sea-Mos Sista
</p>
                <hr style={{ border: 'none', borderTop: '1px solid #eaeaea', width: '100%', borderColor: '#cccccc', margin: '20px 0' }} />
                <p style={{ fontSize: '12px', lineHeight: '24px', margin: '16px 0', color: '#8898aa', textAlign: 'center' }}>© Copyright 2024, All Rights Reserved by E&E Media LLC.</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>)

  setFormData(null)
    const emailParams = {
        from_name: 'SeaMos-Sista LLC.',
        user_email: formData?.email,
        to_name: formData?.firstName,
    };

    emailjs.send('service_uw5lx3d', 'template_nii5u7f', emailParams, 'Y7KOKlfOjV6RsgFe5', {
        publicKey: 'Y7KOKlfOjV6RsgFe5',
        to_name: formData?.firstName,
        from_name: 'SeaMos-Sista LLC.',
        message: htmlContent,
    })
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });

      const secondEmailMessage = `
      First Name: ${formData?.firstName}
      Last Name: ${formData?.lastName}
      Email: ${formData?.email}
      Message: ${formData?.message}
      `
       // Send the additional email
       const additionalEmailParams = {
        from_email: formData?.email,
        to_email: 'seamossforms@gmail.com',
        user_email: 'enriquemgreene@gmail.com',
        from_name: formData?.firstName,
        message: secondEmailMessage,
        to_name: 'SeaMos-Sista LLC.'
      };
    
      emailjs.send('service_uw5lx3d', 'template_9n1cljg', additionalEmailParams, 'Y7KOKlfOjV6RsgFe5', {
        publicKey: 'Y7KOKlfOjV6RsgFe5',
        to_name: formData?.firstName,
        from_name: 'SeaMos-Sista LLC.',
        message: additionalEmailParams,
    })
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });

  };
 
   useEffect(() => {
    console.log('')
   }, [formData])
  const [stepsComplete, setStepsComplete] = useState(0);
  const numSteps = 1;

  const handleSetStep = (num) => {
    if (isValid) {
    if (
      (stepsComplete === 0 && num === -1) ||
      (stepsComplete === numSteps && num === 1)
    ) {
      return;
    }

    setStepsComplete((pv) => pv + num);
  }
  };

  const onSubmit = (data) => {
    if (isValid) {
    reset();
    clearErrors();
    setValue('message', '');
    setComplete(true)
    }
  }

const { handleSubmit, register, setValue, reset, clearErrors, formState: { errors, isValid, isDirty } } = useForm();
  return (
    <div className="flex items-center">
      <div className="p-8 bg-white shadow-lg rounded-md w-full max-w-2xl mx-auto">
       
       
        <form onSubmit={handleSubmit(onSubmit)}>
          {!complete ? (
    <div 
      className="flex flex-col space-y-6 rounded-xl bg-white h-fit px-12 py-12 text-base text-left"
      style={{
        boxShadow: '0px 8px 40px -2px rgba(6, 12, 59, 0.08)'
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="w-full inline-block md:pr-4">
          <label className="text-[#060C3C] font-medium">
            First Name
          </label>
          <input
            className={`${
              errors.firstName?.message ? "border-red-500" : "border-[#B4B6C4]"
            } text-center md:text-left bg-white border  border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block  shadow-none  px-2.5 py-3 mt-[10px]`}
            placeholder="First Name..."
            required
            type="text"
            value={formData?.firstName}
            {...register("firstName", {
              required: "Please fill out this field.",
              minLength: {
                value: 3,
                message: "Name must have at least 3 characters.",
              },
            })}
            onChange={(event) => {
              setFormData({
                ...formData,
                firstName: event.target.value,
              });
              setValue("firstName", event.target.value);
            }}
          />
          <br />
          {errors.firstName && (
            <Alert sx={{ mt: 1 }} severity="error">
              {errors.firstName?.message}
            </Alert>
          )}
        
        </div>
        <div className="w-full inline-block mt-6 md:mt-0">
          <label
            className="text-[#060C3C] font-medium" 
            htmlFor="last_name"
          >
            Last Name
          </label>
          <input
            className="text-center bg-white border border-[#B4B6C4] border-solid  text-gray-800 placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500  shadow-none  px-2.5 py-3 mt-[10px]"
            id="last_name"
            name="last_name"
            placeholder="Last Name..."
            type="text"
            value={formData?.lastName}
            onChange={(event) => setFormData({...formData, lastName: event.target.value})}
         
          />
        </div>
      </div>
      <div className="w-full inline-block">
          <label className="text-[#060C3C] font-medium">
            3. Email Address*
          </label>
          <input
            className="w-full text-center md:text-left bg-white border border-[#B4B6C4] border-solid  placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full focus:ring-blue-500 focus:border-blue-500 block shadow-none  px-2.5 py-3  mt-[10px]"
            id="email"
            placeholder="Email..."
            required
            type="email"
            value={formData?.email}
            {...register("email", {
              required: "Please fill out this field.",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Please enter a valid email address.",
              },
            })}
            onChange={(event) => {
              setFormData({
                ...formData,
                email: event.target.value,
              });
              setValue("email", event.target.value);
            }}
          />
          {errors.email && (
            <Alert sx={{ mt: 1 }} severity="error">
              {errors.email?.message}
            </Alert>
          )}
        </div>
      <div>
        <label
          className="text-[#060C3C] font-medium"
          htmlFor="message"
        >
          Message
        </label>
        <textarea
            className={`${
              errors.message?.message ? "border-red-500" : "border-[#B4B6C4]"
            } text-center md:text-left bg-white border  border-solid placeholder-text-[#B4B6C4] text-[#060C3C] text-sm rounded-lg w-full inline-block  shadow-none  px-2.5 py-3 mt-[10px]`}
            placeholder="Enter message here..."
            required
            type="text"
            value={formData?.message}
            {...register('message', {
              required: "Please fill out this field.",
              minLength: {
                value: 3,
                message: "Name must have at least 3 characters.",
              },
            })}
            onChange={(event) => {
              setFormData({
                ...formData,
                message: event.target.value,
              });
              setValue("message", event.target.value);
            }}
          />
          <br />
          {errors.message && (
            <Alert sx={{ mt: 1 }} severity="error">
              {errors.message?.message}
            </Alert>
          )}
      </div>
      <Button type='submit' gradientDuoTone='cyanToBlue' onClick={() => { if(isValid) { handleSetStep(1); sendEmail()} }}>SEND MESSAGE</Button>
      <br/>
      <Steps numSteps={numSteps} stepsComplete={stepsComplete} />
    </div> ): ( <div 
      className="flex flex-col space-y-6 rounded-xl bg-white h-fit px-12 py-12 text-base text-left"
      style={{
        boxShadow: '0px 8px 40px -2px rgba(6, 12, 59, 0.08)'
      }}
    ><div className="w-full inline-block md:pr-4"><div class="pt-16 pb-8 text-center sm:py-8">
    <svg class="w-10 h-10 mx-auto text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
    </svg>
    <h1 class="mt-4 text-2xl font-bold text-gray-900">We received your message!</h1>
    <p class="mt-2 text-sm font-normal text-gray-600">Hang tight, a member of our team will reach out via email shortly!</p>
</div><Steps numSteps={numSteps} stepsComplete={stepsComplete} /></div></div>)} 

    </form>
     
        
        {/* Buttons */}
        {/* <div className="flex items-center justify-end gap-2">
          <button
            className="px-4 py-1 rounded hover:bg-gray-100 text-black"
            onClick={() => handleSetStep(-1)}
          >
            Prev
          </button>
          <button
            className="px-4 py-1 rounded bg-black text-white"
            onClick={() => handleSetStep(1)}
          >
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
};

const Steps = ({ numSteps, stepsComplete }) => {
  const stepArray = Array.from(Array(numSteps).keys());

  return (
    <div className="flex items-center justify-center mx-auto ">
      {stepArray.map((num) => {
        const stepNum = num + 1;
        const isActive = stepNum <= stepsComplete;
        return (
          <React.Fragment key={stepNum}>
            <Step num={stepNum} isActive={isActive} />
            {stepNum !== stepArray.length && (
              <div className="flex justify-center mx-auto w-full h-1 rounded-full  relative">
                <motion.div
                  className="absolute top-0 bottom-0 left-0 bg-indigo-600 rounded-full"
                  animate={{ width: isActive ? "100%" : 0 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Step = ({ num, isActive }) => {
  return (
    <div className="relative">
      <div
        className={`w-10 h-10 flex items-center justify-center shrink-0 border-2 rounded-full font-semibold text-sm relative z-10 transition-colors duration-300 ${
          isActive
            ? "border-indigo-600 bg-indigo-600 text-white"
            : "border-gray-300 text-gray-300"
        }`}
      >
        <AnimatePresence mode="wait">
          {isActive ? (
            <motion.svg
              key="icon-marker-check"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1.6em"
              width="1.6em"
              xmlns="http://www.w3.org/2000/svg"
              initial={{ rotate: 180, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              exit={{ rotate: -180, opacity: 0 }}
              transition={{ duration: 0.125 }}
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
            </motion.svg>
          ) : (
            <motion.span
              key="icon-marker-num"
              initial={{ rotate: 180, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              exit={{ rotate: -180, opacity: 0 }}
              transition={{ duration: 0.125 }}
            >
              {num}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {isActive && (
        <div className="absolute z-0 -inset-1.5 bg-indigo-100 rounded-full animate-pulse" />
      )}
    </div>
  );
};

export default SteppedProgress;