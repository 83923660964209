import React, { useState } from "react";
import { FiMenu, FiArrowRight, FiX, FiChevronDown } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import navLogo from "../../images/food.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useLocation } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import {
  useMotionValueEvent,
  AnimatePresence,
  useScroll,
  motion,
} from "framer-motion";
import {
  Divider,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { CartContext } from "../Cart/CartContext";
import { useContext, useRef } from "react";
import CartProduct from "../Cart/CartProduct";
import { useNavigate } from "react-router-dom";
import NewCart from "../Cart/NewCart";
import { getProductData, getProductData1 } from "../Products/ProductsArray";
import { Button } from "flowbite-react";
import { animate, useMotionTemplate, useMotionValue } from "framer-motion";



const zipCodes = [
  46801, 46802, 46803, 46804, 46805, 46806, 46807, 46808, 46809, 46814, 46815,
  46816, 46817, 46818, 46819, 46825, 46835, 46845, 46850, 46851, 46852, 46853,
  46854, 46855, 46856, 46857, 46858, 46859, 46860, 46861, 46862, 46863, 46864,
  46865, 46866, 46867, 46868, 46869, 46885, 46895, 46896, 46898, 46899,
];

const validFortWayneZipCodes = zipCodes.map((zipCode) => zipCode.toString());

const FlyoutNav = () => {
  const cart = useContext(CartContext);

  const turn = useMotionValue(0);
  const inputRef = useRef(null);
  React.useEffect(() => {
    animate(turn, 1, {
      ease: "linear",
      duration: 5,
      repeat: Infinity,
    });
  }, []);

  const backgroundImage = useMotionTemplate`conic-gradient(from ${turn}turn, #a78bfa00 75%, #a78bfa 100%)`;
  const [isLocalPickupDisabled, setIsLocalPickupDisabled] =
    React.useState(false);
  // ZIP CODE STUFF
  const [isValid, setIsValid] = React.useState(true);
  const [zipCode, setZipCode] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState("");
  const [description, setDescription] = React.useState([]);
  const [isEligible, setIsEligible] = React.useState("");
  const [shippingOption, setShippingOption] = useState("");
  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };

  const [selectedValue, setSelectedValue] = React.useState("a");
  React.useEffect(() => {
    // Your side effect logic here
    console.log(selectedOption, isEligible);
  }, [selectedOption, isEligible]);

  const checkDistance = async () => {
    let isZipCodeEligible = validFortWayneZipCodes.includes(zipCode);
    const gelOptions = cart.items[0]?.description;
    if (isZipCodeEligible) {
      console.log("Within 25 miles");
      setIsEligible(true);
      setIsValid(true);
      setSelectedOption("localDelivery");
      setDescription(gelOptions);
      setIsLocalPickupDisabled(false);
    } else {
      console.log("Not within 25 miles");
      setIsEligible(false);
      setIsValid(false);
      setSelectedOption("groundShipping");
      setDescription(gelOptions);
      setIsLocalPickupDisabled(true);
    }
    console.log(gelOptions);
  };
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    let isZipCodeEligible = validFortWayneZipCodes.includes(zipCode);
    setValue(event.target.value);
    if (event.target.value === "groundShipping") {
      setIsEligible(true);
      setSelectedOption("groundShipping");
    } else if (
      event.target.value === "localDelivery" &&
      selectedOption === ""
    ) {
      setIsEligible(false);
    } else if (!isZipCodeEligible) {
      setIsEligible(false);
    } else if (event.target.value === "localDelivery") {
      setSelectedOption("localDelivery");
    }
  };
  const trueValues = JSON.parse(localStorage.getItem("trueValues"));
  console.log(trueValues);

  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  const [isAdded, setIsAdded] = React.useState(false);
  const [juice, setJuice] = React.useState(false);
  const [collection, setCollection] = React.useState(false);
  const [cleanse, setCleanse] = React.useState(false);
  const [addedProduct, setAddedProduct] = React.useState(null);
  const handleProductAdded = (product) => {
    setAddedProduct(product);
  };
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = React.useState(false);

  const handleNavLinkClick = () => {
    setIsTransitioning(true);
    // Perform any necessary actions before transitioning
  };

  const totalCost = cart.getTotalCost().toFixed(2);
  console.log(totalCost);
  const checkout = async () => {
    const cacheBuster = new Date().getTime(); // Get the current timestamp as a cache-busting parameter
    await fetch(`/api/checkout?cacheBuster=${cacheBuster}`, {
      // Append the cacheBuster parameter to the URL
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json", // Add this line
      },
      body: JSON.stringify({
        items: cart.items,
        selectedOption: selectedOption,
        totalCost: totalCost,
        juice: juice,
        collection: collection,
        cleanse: cleanse,
      }),
    })
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response.url) {
          window.location.assign(response.url);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  let dog1 = cart.items.map((product) => {
    return getProductData(product.id);
  });
  const [scrolled, setScrolled] = useState(false);
  const [smallNav, setSmallNav] = useState(false);
  const [test, setTest] = useState(0);
  const { scrollY } = useScroll();
  React.useEffect(() => {
    const juiceProducts = dog1
      .flat()
      .filter((product) => product?.name.toLowerCase().includes("juice"));
    const cleanseProducts = dog1
      .flat()
      .filter((product) => product?.name.toLowerCase().includes("cleanse"));
    const collectionProducts = dog1
      .flat()
      .filter((product) => product?.name.toLowerCase().includes("collection"));

    setJuice([...juiceProducts]);
    setCleanse([...cleanseProducts]);
    setCollection([...collectionProducts]);

    if (selectedOption === "localDelivery" && totalCost <= 55) {
      setTest(10);
    } else if (
      selectedOption === "localDelivery" &&
      totalCost > 55 &&
      totalCost < 150
    ) {
      setTest(5);
    } else if (selectedOption === "localDelivery" && totalCost >= 150) {
      setTest(0);
    } else if (
      juice.length === 0 &&
      collection.length === 0 &&
      cleanse.length === 0 &&
      selectedOption === "groundShipping" &&
      totalCost <= 149
    ) {
      setTest(15);
    } else if (
      cleanse.length > 0 &&
      selectedOption === "groundShipping" &&
      totalCost <= 149
    ) {
      setTest(50);
    } else if (
      juice.length > 0 &&
      selectedOption === "groundShipping" &&
      totalCost <= 149
    ) {
      setTest(23);
    } else if (
      collection.length > 0 &&
      selectedOption === "groundShipping" &&
      totalCost <= 149
    ) {
      setTest(23);
    } else if (selectedOption === "groundShipping" && totalCost >= 150) {
      setTest(0);
    }
  }, [
    cleanse.length,
    selectedOption,
    totalCost,
    juice.length,
    collection.length,
  ]);

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrolled(latest > 100 ? true : false);
    setSmallNav(latest > 100 ? true : false);
  });
  let dog = cart.items.map((product) => {
    return getProductData1(product.id);
  });

  let filteredArray = dog.filter((_, index) => index !== 1);
  for (let i = 0; i < filteredArray.length; i++) {
    if (filteredArray[i] === null) {
      return null;
    }
  }

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
    setIsValid(true);
  }

  const handleChangeColor = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  return (
    <React.Fragment>
      {/* <div className="sticky left-0 right-0 top-0 z-50 w-full bg-indigo-600 px-2 text-white shadow-md">
    <div className="mx-auto flex items-center justify-center text-sm md:text-sm h-full">
        <p>Be on the lookout for exciting new products!</p>
    </div>
</div> */}
      <nav
        className={`fixed  z-50 w-full px-6  py-2
      transition-all duration-300 ease-out lg:px-12
      ${
        scrolled ? "bg-[#efefef] shadow-xl" : "bg-neutral-950/0 shadow-none "
      } ${smallNav ? "h-28" : "h-28"} `}
      >
        <div
          className={`${
            smallNav ? "h-28" : "h-28"
          } mx-auto flex max-w-7xl items-center justify-between`}
        >
          <Logo />
          <div className="hidden gap-6 lg:flex">
            <Links />
            {/* <CTAs /> */}
          </div>
          <MobileMenu />
        </div>
      </nav>
      <>
        {window.location.pathname === "/cart" ? (
          <>
            {productsCount > 0 ? (
              <section class="bg-white py-40">
                <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                  <div class="max-w-6xl mx-auto">
                    <div class="grid grid-cols-1 lg:grid-cols-5 lg:items-start xl:grid-cols-6 gap-y-10 lg:gap-x-12 xl:gap-x-16">
                      <div class="lg:col-span-3 xl:col-span-4">
                        <h1 class="text-2xl font-bold text-gray-900">
                          Your Cart
                        </h1>

                        <div class="hidden mt-8 lg:grid lg:grid-cols-2 lg:gap-x-16 xl:gap-x-64">
                          <div class="flex-1">
                            <p class="text-xs font-bold tracking-widest text-gray-400 uppercase">
                              Product
                            </p>
                          </div>

                          <div class="sm:grid sm:grid-cols-2 lg:gap-x-0 xl:gap-x-1 ">
                            <div>
                              <p class="text-xs font-bold tracking-widest text-gray-400 uppercase">
                                Quantity
                              </p>
                            </div>

                            <div>
                              <p class="text-xs font-bold tracking-widest text-gray-400 uppercase">
                                Price
                              </p>
                            </div>
                          </div>
                        </div>

                        <hr class="mt-8 border-gray-200 lg:mt-4" />

                        {cart.items.map((currentProduct, idx) => (
                          <div>
                            <NewCart
                              key={idx}
                              id={currentProduct.id}
                              quantity={currentProduct.quantity}
                              description={currentProduct.description}
                              selectedChoice={localStorage.getItem('selectedChoice')}
                            />
                          </div>
                        ))}
                      </div>

                      <div class="lg:col-span-2 lg:sticky lg:top-6">
                        <div class="overflow-hidden bg-gray-900 rounded-md">
                          <div class="px-4 py-6 sm:p-6 lg:p-8">
                            <h2 class="text-2xl font-bold text-white">
                              Cart total
                            </h2>

                            <div class="flow-root mt-5">
                              <div class="-my-6 divide-y divide-gray-700">
                                <div class="flex items-center justify-between py-6">
                                  <p class="text-base font-medium text-white">
                                    Subtotal
                                  </p>
                                  <p class="text-base font-medium text-white">
                                    ${cart.getTotalCost().toFixed(2)}
                                  </p>
                                </div>

                                <div class="py-6 space-y-4">
                                  {/* <div class="flex items-center justify-between">
                            <p class="text-base font-bold text-gray-300">Tax</p>
                            <p class="text-base font-bold text-white">$0</p>
                        </div> */}

                                  <div>
                                    <p class="text-base font-bold text-gray-300">
                                      Shipping Options:
                                    </p>
                                    <div className="bg-gray-200 flex pt-3 pl-3 pr-3 items-center justify-center text-center rounded-lg">
                                      <p className=" font-bold ">
                                        Please note that our processing times range from 3-5 business days.
                                      </p>
                                    </div>
                                    <br/>
                                    <div class="flex  flex-col">
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby="demo-controlled-radio-buttons-group"
                                          name="controlled-radio-buttons-group"
                                          value={value}
                                          onChange={handleChange}
                                        >
                                          <div className="flex justify-between gap-4">
                                            <FormControlLabel
                                              className="text-white"
                                              value="localDelivery"
                                              control={
                                                <Radio
                                                  sx={{ color: "white" }}
                                                />
                                              }
                                              label="Local Delivery"
                                            />
                                            <p className="mt-3 text-white">
                                              {isEligible &&
                                              selectedOption === "localDelivery"
                                                ? `$${test}`
                                                : "--|--"}
                                            </p>
                                          </div>
                                          {value === "localDelivery" && (
                                            <FormControl>
                                              <div className="flex items-center gap-4">
                                                <input
                                                  autoFocus
                                                  type="text"
                                                  value={zipCode}
                                                  onChange={handleZipCodeChange}
                                                  className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 border rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                                                />
                                                <button
                                                  className="text-white font-medium px-3 py-1 rounded-md overflow-hidden relative transition-transform hover:scale-105 active:scale-95"
                                                  onClick={checkDistance}
                                                >
                                                  <span className="relative z-10">
                                                    Check Eligibility
                                                  </span>
                                                  <motion.div
                                                    initial={{ left: 0 }}
                                                    animate={{ left: "-300%" }}
                                                    transition={{
                                                      repeat: Infinity,
                                                      repeatType: "mirror",
                                                      duration: 4,
                                                      ease: "linear",
                                                    }}
                                                    className="bg-[linear-gradient(to_right,#8f14e6,#e614dc,#e61453,#e68414,#e6e614)] absolute z-0 inset-0 w-[400%]"
                                                  ></motion.div>
                                                </button>
                                              </div>
                                              {!isValid && (
                                                <p style={{ color: "red" }}>
                                                  You are not eligible. Please
                                                  select Ground Shipping.
                                                </p>
                                              )}
                                              {isEligible && (
                                                <p style={{ color: "green" }}>
                                                  You are eligible!
                                                </p>
                                              )}
                                            </FormControl>
                                          )}

                                          <div className="flex justify-between gap-4">
                                            <FormControlLabel
                                              className="text-white"
                                              onChange={() =>
                                                setIsEligible(true)
                                              }
                                              value="groundShipping"
                                              control={
                                                <Radio
                                                  sx={{ color: "white" }}
                                                />
                                              }
                                              label="Ground Shipping"
                                            />
                                            <p className="mt-3 text-white">
                                              {isEligible &&
                                              selectedOption ===
                                                "groundShipping"
                                                ? `$${test}`
                                                : "--|--"}
                                            </p>
                                          </div>
                                        </RadioGroup>
                                      </FormControl>

                                      {/* <p class="mt-3 text-sm font-normal text-gray-400">We only charge for shipping if you reside outside of our local delivery region.</p> */}
                                    </div>

                                    {/* The Code for The Shipping Options */}
                                  </div>
                                </div>

                                <div class="flex items-center justify-between py-6">
                                  <p class="text-base font-bold text-white">
                                    Grand Total:
                                  </p>
                                  <p class="text-base font-bold text-white">
                                    $
                                    {isEligible
                                      ? (
                                          cart.getTotalCost() + parseFloat(test)
                                        ).toFixed(2)
                                      : cart.getTotalCost().toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="mt-6 mx-auto flex justify-center">
                              <button
                                disabled={!isEligible}
                                type="button"
                                className={`text-white font-medium px-3 py-2 rounded-md overflow-hidden relative transition-transform hover:scale-105 active:scale-95 ${
                                  !isEligible ? "filter grayscale" : ""
                                }`}
                                onClick={checkout}
                              >
                                <span className="relative z-10">
                                  Continue to Payment
                                </span>
                                <motion.div
                                  initial={{ left: 0 }}
                                  animate={{ left: "-300%" }}
                                  transition={{
                                    repeat: Infinity,
                                    repeatType: "mirror",
                                    duration: 4,
                                    ease: "linear",
                                  }}
                                  className="bg-[linear-gradient(to_right,#8f14e6,#e614dc,#e61453,#e68414,#e6e614)] absolute z-0 inset-0 w-[400%]"
                                ></motion.div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <div className="h-screen flex items-center justify-center">
                {" "}
                <Typography
                  variant="h1"
                  sx={{ fontFamily: "Bebas Neue", padding: "20px" }}
                  className="text-center"
                >
                  Your Shopping Cart is empty.
                </Typography>
              </div>
            )}
          </>
        ) : null}
      </>
    </React.Fragment>
  );
};

const Logo = () => {
  return (
    <div>
      <a href="/" className="">
        <img className="w-60" src={navLogo} alt="" />
      </a>
    </div>
  );
};
const CTAs = () => {
  const cart = useContext(CartContext);
  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  return (
    <div className="flex items-center gap-3">
      <a href="/contact">
        {/* <button className="flex items-center gap-2 rounded-lg border-1 border-[#D8B177] px-4 py-2 font-semibold text-white bg-[#D8B177] transition-colors hover:bg-purple ">
        <FaUserCircle />
        <span>Contact Us</span>
      </button> */}
        <Button
          className=" font-sans
                            text-base
                            font-semibold
                            sm:leading-8
                            sm:text-lg"
          gradientDuoTone="cyanToBlue"
        >
          CONTACT US
        </Button>
      </a>
      <Box>
        <Button href="/cart" sx={{ color: "#2196F3" }}>
          <ShoppingCartIcon />
          {productsCount > 0 ? <span>{productsCount}</span> : null}
        </Button>
      </Box>
    </div>
  );
};

const Links = () => {
  const cart = useContext(CartContext);
  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  return (
    <div className="flex items-center gap-6 ">
      {LINKS.map((l) => (
        <NavLink key={l.text} href={l.href} FlyoutContent={l.component}>
          {l.text}
        </NavLink>
      ))}
      <a href="/contact">
        {/* <button className="flex items-center gap-2 rounded-lg border-1 border-[#D8B177] px-4 py-2 font-semibold text-white bg-[#D8B177] transition-colors hover:bg-purple ">
        <FaUserCircle />
        <span>Contact Us</span>
      </button> */}
        <Button
          className=" font-sans
                            text-base
                            font-semibold
                            sm:leading-8
                            sm:text-lg"
          gradientDuoTone="cyanToBlue"
        >
          CONTACT US
        </Button>
      </a>
      <Box>
        <Button href="/cart" sx={{ color: "#2196F3" }}>
          <ShoppingCartIcon />
          {productsCount > 0 ? <span>{productsCount}</span> : null}
        </Button>
      </Box>
    </div>
  );
};

const NavLink = ({ children, href, FlyoutContent }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [smallNav, setSmallNav] = useState(false);
  const { scrollY } = useScroll();
  const showFlyout = FlyoutContent && open;
  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrolled(latest > 250 ? true : false);
    setSmallNav(latest > 250 ? true : false);
  });
  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <a
        href={href}
        className={` 
                            font-sans
                            text-base
                            font-normal
                            sm:leading-8
                            sm:text-lg
                             relative ${
                               location.pathname === "/" && scrolled
                                 ? "text-[#2196F3]"
                                 : location.pathname !== "/"
                                 ? "text-[#2196F3]"
                                 : "text-white"
                             }`}
      >
        {children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out"
        />
      </a>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-white text-black"
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const MobileMenuLink = ({ children, href, FoldContent, setMenuOpen }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative text-neutral-950">
      {FoldContent ? (
        <div
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
          onClick={() => setOpen((pv) => !pv)}
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(false);
            }}
            href={href}
          >
            {children}
          </a>
          <motion.div
            animate={{ rotate: open ? "180deg" : "0deg" }}
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
          >
            <FiChevronDown />
          </motion.div>
        </div>
      ) : (
        <a
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(false);
          }}
          href={href}
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
        >
          <span>{children}</span>
          <FiArrowRight />
        </a>
      )}
      {FoldContent && (
        <motion.div
          initial={false}
          animate={{
            height: open ? "fit-content" : "0px",
            marginBottom: open ? "24px" : "0px",
            marginTop: open ? "12px" : "0px",
          }}
          className="overflow-hidden"
        >
          <FoldContent />
        </motion.div>
      )}
    </div>
  );
};

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const cart = useContext(CartContext);
  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );
  return (
    <div className="block lg:hidden">
      <button onClick={() => setOpen(true)} className="block text-3xl">
        <FiMenu />
      </button>
      <AnimatePresence>
        {open && (
          <motion.nav
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            exit={{ x: "100vw" }}
            transition={{ duration: 0.15, ease: "easeOut" }}
            className="fixed left-0 top-0 flex h-screen w-full flex-col bg-white"
          >
            <div className="flex items-center justify-between p-6">
              <Logo color="black" />
              <button onClick={() => setOpen(false)}>
                <FiX className="text-3xl text-neutral-950" />
              </button>
            </div>
            <div className="h-screen overflow-y-scroll bg-neutral-100 p-6">
              {MOBILELINKS.map((l) => (
                <MobileMenuLink
                  key={l.text}
                  href={l.href}
                  FoldContent={l.component}
                  setMenuOpen={setOpen}
                >
                  {l.text}
                </MobileMenuLink>
              ))}
     
            </div>
            {/* <div className="flex justify-end bg-neutral-950 p-6">
              <CTAs />
            </div> */}
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FlyoutNav;

const LINKS = [
  {
    text: "HOME",
    href: "/",
  },
  {
    text: "SHOP",
    href: "/shop",
  },
  {
    text: "ABOUT US",
    href: "/about-us",
  },
];

const MOBILELINKS = [
  {
    text: "HOME",
    href: "/",
  },
  {
    text: 'CART',
    href: '/cart'
  },
  {
    text: "SHOP",
    href: "/shop",
  },
  {
    text: "ABOUT US",
    href: "/about-us",
  },
 
  {
    text: 'CONTACT US',
    href: '/contact'
  }
];
