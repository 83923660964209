import React from 'react'
import { Typography } from '@mui/material'
const Disclaimer = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 820);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize, { passive: true });

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize, { passive: true });
    };
  }, []);
  return (
    <div className='p_container'>
        <div style={{padding: isMobile ? '0px' : '100px', height: isMobile ? '100%' :'65dvh'}}>
        <Typography variant='h1' sx={{fontFamily: 'Bebas Neue', color: '#557174'}} className='text-center'>Legal Disclaimer</Typography>
        <Typography variant='h6' style={{color: 'black', padding: '10px'}} className='text-center'>**The information and statements about the products listed have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease. You should not use the information contained here for diagnosing or treating a health problem or disease, or for prescribing any medication. We recommend that you consult with a qualified healthcare practitioner before using any herbal products if you are on any other medications.**</Typography>
        </div>
        </div>
  )
}

export default Disclaimer